<template>
	<div>
		<div class="mailgenerator" v-if="newMail">
			<v-card class="pa-4">
				<v-form @submit="handleNewMail" ref="sendNewMail">
					<div class="d-flex">
						<v-btn outlined color="primary">To</v-btn>
						<v-autocomplete
							class="mt-0 pt-0 ms-3"
							v-model="recipients"
							@change="ccRecipients = []"
							:items="contacts"
							item-text="email"
							item-value="externalContactId"
							:rules="[myrules.required]"
							single-line
							chips
							small-chips
							multiple
						></v-autocomplete>
						<!-- <small class="primary--text">BCC</small> -->
					</div>
					<div class="d-flex">
						<v-btn outlined color="primary">Cc</v-btn>
						<v-autocomplete
							class="mt-0 pt-0 ms-3"
							v-model="ccRecipients"
							:disabled="
								recipients.length == 0 ||
								recipients.length == contacts.length
							"
							:items="contactsFiltered()"
							item-text="email"
							item-value="email"
							single-line
							chips
							small-chips
							multiple
						></v-autocomplete>
					</div>
					<v-text-field
						class="mt-0 pt-0"
						label="Add a Subject"
						v-model="subject"
						:rules="[myrules.required]"
						single-line
					></v-text-field>
					<div
						class="d-flex"
						v-if="selectedOpportunity.opportunityName"
					>
						<strong>Opportunity:</strong>
						<small class="ms-3 mt-1">{{
							selectedOpportunity.opportunityName
						}}</small>
					</div>
					<div class="d-flex mb-3" v-if="selectedProject.projectName">
						<strong>Project:</strong>
						<small class="ms-3 mt-1">{{
							selectedProject.projectName
						}}</small>
					</div>
					<VueEditor v-model="message_body" />
					<div>
						<v-btn
							color="primary"
							type="submit"
							class="mt-2 me-2"
							:disabled="newMailLoader"
						>
							<span v-if="!newMailLoader">Send</span>
							<v-progress-circular v-else indeterminate size="20"
						/></v-btn>
						<v-btn
							outlined
							class="me-2 mt-2"
							@click="handleDiscard()"
							>Discard</v-btn
						>
						<v-icon
							class="me-2 mt-2 pointer"
							@click="addAttachment = !addAttachment"
							>mdi-link-plus</v-icon
						>
						<v-icon
							class="me-2 mt-2 pointer"
							@click="addAttachment = !addAttachment"
							>mdi-image-outline</v-icon
						>

						<v-btn
							v-if="selectedOpportunity.opportunityId"
							color="error"
							class="me-2 mt-2"
							@click="selectedOpportunity = {}"
							>remove opportunity</v-btn
						>
						<v-btn
							v-else
							color="dark"
							class="me-2 mt-2"
							:disabled="selectedProject.projectId"
							@click="addOpportunity = !addOpportunity"
							>Link to opportunity</v-btn
						>
						<v-btn
							v-if="selectedProject.projectId"
							color="error"
							class="me-2 mt-2"
							@click="selectedProject = {}"
							>remove project</v-btn
						>
						<v-btn
							v-else
							color="dark"
							class="me-2 mt-2"
							:disabled="selectedOpportunity.opportunityId"
							@click="addProject = !addProject"
							>link to project</v-btn
						>
					</div>
				</v-form>
			</v-card>
		</div>
		<v-dialog v-model="addAttachment" max-width="500">
			<v-card class="card-shadow">
				<v-card-text>
					<div class="d-flex justify-space-between pt-5 pb-3">
						<h2 class="primary--text">Add Attachment</h2>
						<v-icon @click="addAttachment = !addAttachment"
							>mdi-close</v-icon
						>
					</div>
					<v-divider></v-divider>
					<div class="mt-5">
						<v-file-input
							v-model="attachment"
							label="File input"
						></v-file-input>
						<div class="text-end">
							<v-btn
								color="primary"
								@click="addAttachment = !addAttachment"
								>Save</v-btn
							>
						</div>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog v-model="addOpportunity" max-width="500">
			<v-card class="card-shadow">
				<v-card-text>
					<div class="d-flex justify-space-between pt-5 pb-3">
						<h2 class="primary--text">Select Opportunity</h2>
						<v-icon
							@click="
								(addOpportunity = !addOpportunity),
									(opportunitySearch = '')
							"
							>mdi-close</v-icon
						>
					</div>
					<v-divider></v-divider>
					<div class="mt-5">
						<v-text-field
							label="Search Opportunity"
							outlined
							dense
							v-model="opportunitySearch"
						></v-text-field>
						<v-list nav dense>
							<v-list-item
								link
								active-class="bg-primary primary--text"
								v-for="(oppor, idx) in filteredOpportunity(
									opportunitySearch
								)"
								:key="idx"
								@click="
									(selectedOpportunity = oppor),
										(addOpportunity = !addOpportunity)
								"
							>
								<v-list-item-title>{{
									oppor.opportunityName
										? oppor.opportunityName
										: 'N/A'
								}}</v-list-item-title>
							</v-list-item>
						</v-list>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog v-model="addProject" max-width="500">
			<v-card class="card-shadow">
				<v-card-text>
					<div class="d-flex justify-space-between pt-5 pb-3">
						<h2 class="primary--text">Select Project</h2>
						<v-icon
							@click="
								(addProject = !addProject), (projectSearch = '')
							"
							>mdi-close</v-icon
						>
					</div>
					<v-divider></v-divider>
					<div class="mt-5">
						<v-text-field
							label="Search Project"
							outlined
							dense
							v-model="projectSearch"
						></v-text-field>
						<v-list nav dense>
							<v-list-item
								link
								active-class="bg-primary primary--text"
								v-for="(project, idx) in filteredProjects(
									projectSearch
								)"
								:key="idx"
								@click="
									(selectedProject = project),
										(addProject = !addProject)
								"
							>
								<v-list-item-title>{{
									project.projectName
										? project.projectName
										: 'N/A'
								}}</v-list-item-title>
							</v-list-item>
						</v-list>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import { VueEditor } from 'vue2-editor';
	import { mapActions } from 'vuex';
	export default {
		components: {
			VueEditor,
		},
		data() {
			return {
				addOpportunity: false,
				selectedOpportunity: {},
				opportunitySearch: '',
				projectSearch: '',
				addProject: false,
				selectedProject: {},
				subject: '',
				message_body: '',
				recipients: [],
				ccRecipients: [],
				attachment: '',
				addAttachment: false,
			};
		},
		watch: {
			mySignature: function () {
				this.message_body =
					'<br/><br/><br/><br/><br/>' +
					this.mySignature.signatureText;
			},
			deep: true,
		},
		methods: {
			...mapActions(['setShowContact', 'setNewMail']),
			handleNewMail(e) {
				e.preventDefault();
				let validate = this.$refs.sendNewMail.validate();
				if (validate) {
					let recipientsEmail = [];
					this.recipients &&
						this.recipients.map(rec => {
							this.contacts &&
								this.contacts.filter(contact => {
									if (contact.externalContactId == rec) {
										return recipientsEmail.push({
											email: contact.email,
											id: contact.externalContactId,
										});
									}
								});
						});
					if (recipientsEmail.length > 0) {
						let obj = {
							subject: this.subject,
							message: this.message_body,
							recipient_emails: recipientsEmail,
							attachment: this.attachment,
							oppurtunity_id: this.selectedOpportunity
								.opportunityId
								? this.selectedOpportunity.opportunityId
								: '',
							project_id: this.selectedProject.projectId
								? this.selectedProject.projectId
								: '',
							cc: this.ccRecipients.join(','),
						};
						// console.log({ obj });
						this.sendNewMail({
							uid: this.user_id,
							payload: obj,
							onSuccess: () => {
								this.handleDiscard();
								this.getSent(this.user_id);
								this.getInbox(this.user_id);
								this.opportunitySearch = '';
								this.projectSearch = '';
								this.recipients = [];
								this.ccRecipients = [];
								alert('Email Sent');
							},
						});
					}
				}
			},
			handleDiscard() {
				this.setNewMail(false);
				this.selectedOpportunity = {};
				this.selectedProject = {};
				this.message_body = '';
				this.subject = '';
				this.opportunitySearch = '';
				this.projectSearch = '';
				this.recipients = [];
				this.message_body =
					'<br/><br/><br/><br/><br/>' +
					this.mySignature.signatureText;
			},
			contactsFiltered() {
				if (this.recipients.length != 0) {
					let obj = this.contacts.filter(contact => {
						if (
							this.recipients.find(
								rec => rec != contact.externalContactId
							)
						) {
							return contact;
						}
					});
					return obj;
				} else {
					return this.contacts;
				}
			},
			backLogic() {
				this.$router.push('/');
				this.setShowContact(true);
			},
		},
		mounted() {
			this.getContacts(this.user_id);
			this.getProjects(this.user_id);
			this.getOpportunities(this.user_id);
		},
	};
</script>
<style scoped>
	.w-100 {
		width: 100% !important;
	}
	.mailgenerator {
		background: #faf9f8;
		position: absolute;
		bottom: 0;
		margin-bottom: 55px;
		height: 480px;
		overflow-y: auto;
		width: calc(100vw - 720px) !important;
		z-index: 1 !important;
	}
	@media (max-width: 1224px) {
		.mailgenerator {
			height: 550px;
			min-width: calc(100vw - 350px) !important;
			margin-bottom: 10px;
		}
	}
	@media (max-width: 959.98px) {
		.mailgenerator {
			min-width: calc(100vw - 320px) !important;
		}
	}
	@media (max-width: 640px) {
		.mailgenerator {
			min-width: calc(100vw - 60px) !important;
		}
	}
</style>
