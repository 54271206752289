<template>
	<div>
		<v-overlay :value="validationLoader">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
		<v-app>
			<v-main>
				<router-view />
			</v-main>
		</v-app>
	</div>
</template>

<script>
	export default {
		name: 'App',
	};
</script>

<style>
	#app {
		background: white !important;
	}

	.card-shadow {
		box-shadow: 0 0 45px rgba(0, 0, 0, 0.05) !important;
	}
	.fs-18 {
		font-size: 18px;
	}
	.fs-20 {
		font-size: 20px;
	}
	.fs-32 {
		font-size: 32px;
	}
	.font-500 {
		font-weight: 500;
	}
	.pointer {
		cursor: pointer;
	}
	::-webkit-scrollbar {
		width: 8px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		box-shadow: inset 0 0 5px grey;
		border-radius: 10px;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #ddd;
		border-radius: 10px;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: rgb(143, 142, 142);
	}
</style>
