import GeneralRepository from './GeneralRepository';
import GmailRepository from './GmailRepository';

const repositories = {
	gmail: GmailRepository,
	general: GeneralRepository,
};

// eslint-disable-next-line import/prefer-default-export
export const RepositoryFactory = {
	get: name => repositories[name],
};
