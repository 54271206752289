<template>
  <div class="mail-details">
    <v-btn
      v-if="checkInbox() || checkSent() || checkArchived()"
      color="#F3F2F1"
      class="me-2 mt-2"
      @click="handleDelete"
    >
      <span v-if="!deleteLoader" :disabled="deleteLoader"
        ><v-icon class="me-2">mdi-trash-can-outline</v-icon> Delete</span
      >
      <v-progress-circular v-else indeterminate size="20" />
    </v-btn>
    <span v-if="checkInbox() || checkSent()">
      <v-btn
        @click="handleArchive"
        color="#F3F2F1"
        class="me-2 mt-2"
        v-if="checkInbox()"
      >
        <span v-if="!archivedLoader" :disabled="archivedLoader"
          ><v-icon class="me-2">mdi-archive-outline</v-icon> Archive</span
        >
        <v-progress-circular v-else indeterminate size="20" />
      </v-btn>
      <v-progress-circular indeterminate size="20" v-if="logsLoader" />
      <span v-else>
        <v-badge
          v-if="Object.keys(logsData).length != 0"
          bordered
          color="error"
          :content="logsData.oppurtunity ? 'Opportunity' : 'Project'"
          overlap
        >
          <v-btn color="#F3F2F1" class="me-2 mt-2">
            {{
              logsData.oppurtunity
                ? logsData.oppurtunity.opportunityName
                  ? logsData.oppurtunity.opportunityName
                  : "N/A"
                : logsData.project
                ? logsData.project.projectName
                  ? logsData.project.projectName
                  : "N/A"
                : "N/A"
            }}
          </v-btn>
        </v-badge>

        <v-btn
          v-if="Object.keys(logsData).length == 0"
          color="#F3F2F1"
          class="me-2 mt-2"
          @click="addOpportunity = true"
        >
          Link to Opportunity
        </v-btn>
        <v-btn
          color="#F3F2F1"
          v-if="Object.keys(logsData).length == 0"
          class="me-2 mt-2"
          @click="addProject = true"
        >
          Link to project
        </v-btn>
      </span>
    </span>
    <span v-if="checkArchived()">
      <v-btn @click="handleMoveToInbox" color="#F3F2F1" class="me-2 mt-2">
        <span v-if="!filterLoader" :disabled="filterLoader"
          ><v-icon class="me-2">mdi-archive-arrow-up-outline</v-icon> Move to
          Inbox</span
        >
        <v-progress-circular v-else indeterminate size="20" />
      </v-btn>
    </span>
    <v-container class="mail-detail-container mt-2">
      <v-btn @click="backLogic" v-if="checkMobileView(false) == false">
        <v-icon>mdi-arrow-left-bold</v-icon>
      </v-btn>
      <div class="d-flex">
        <h3 v-if="checkInbox()">Inbox</h3>
        <h3 v-if="checkSent()">Email Sent</h3>
        <h3 v-if="checkTrash()">Trash</h3>
        <h3 v-if="checkArchived()">Archived</h3>
        <!-- <v-icon class="ms-auto">mdi-plus-box-multiple-outline</v-icon> -->
      </div>
      <div class="email-card" v-if="checkInbox()">
        <v-expansion-panels class="mt-3" v-model="panel">
          <v-expansion-panel
            expand
            v-model="panel"
            v-for="(data, idx) in filteredInbox() &&
            filteredInbox().allMessages"
            :key="idx"
          >
            <v-expansion-panel-header>
              {{ data.subject ? data.subject : "N/A" }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" md="2" lg="1">
                  <v-avatar
                    color="primary"
                    size="40"
                    class="align-center white--text"
                  >
                    {{
                      data.to
                        ? data.to
                          ? data.to.length >
                            (0).trim().substring(0, 1).toUpperCase()
                          : "EP"
                        : "EP"
                    }}
                  </v-avatar>
                </v-col>
                <v-col cols="12" md="10" lg="11" class="ps-3 ps-md-0">
                  <div class="d-flex">
                    <p class="mb-0">
                      {{ data.subject ? data.subject : "N/A" }}
                    </p>
                    <div class="ms-auto">
                      <v-icon
                        @click="
                          (reply = true),
                            (selectedReply = data),
                            scrollToReply()
                        "
                        class="primary--text pointer"
                        >mdi-reply</v-icon
                      >
                      <!-- v-if="
													userDetails &&
													userDetails.userToken
														.type == 'gmail'
												" -->
                      <v-icon
                        @click="
                          (replyAll = true),
                            (reply = true),
                            (selectedReply = data),
                            scrollToReply()
                        "
                        class="mx-3 primary--text pointer"
                        >mdi-reply-all-outline</v-icon
                      >
                      <v-icon
                        class="primary--text pointer"
                        @click="(forward = !forward), (selectedReply = data)"
                        >mdi-arrow-right</v-icon
                      >
                    </div>
                  </div>
                  <small>{{
                    data.internalDate
                      ? momentTimeDate(data.internalDate)
                      : "N/A"
                  }}</small>
                  <div class="mt-2">
                    <div class="d-flex flex-wrap font-weight-bold">
                      <span>To: </span>
                      <p
                        v-for="(rec, idx) in data.to"
                        :key="idx"
                        class="ml-1 mb-0"
                      >
                        {{ rec }}<span v-if="data.to.length - 1 != idx">;</span>
                      </p>
                    </div>
                    <div
                      class="d-flex flex-wrap font-weight-bold"
                      v-if="data.cc.length > 0"
                    >
                      <span>CC: </span>
                      <p
                        v-for="(rec, idx) in data.cc"
                        :key="idx"
                        class="ml-1 mb-0"
                      >
                        {{
                          rec.EmailAddress
                            ? rec.EmailAddress.Address
                              ? rec.EmailAddress.Address
                              : "N/A"
                            : "N/A"
                        }}<span v-if="data.cc.length - 1 != idx">;</span>
                      </p>
                    </div>
                    <br /><br />
                    <span v-html="data.messageText"></span>
                    <br /><br />
                    <span>From: </span>
                    <span>{{ data.from ? data.from : "N/A" }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div class="email-card" v-if="checkSent()">
        <div v-if="threadLoader" class="my-5 text-center">
          <v-progress-circular indeterminate size="40" />
        </div>
        <v-expansion-panels class="mt-3" v-model="panel" v-else>
          <v-expansion-panel
            expand
            v-model="panel"
            v-for="(data, idx) in threads"
            :key="idx"
          >
            <v-expansion-panel-header>
              {{ data.subject ? data.subject : "N/A" }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" md="2" lg="1">
                  <v-avatar
                    color="primary"
                    size="40"
                    class="align-center white--text"
                  >
                    {{
                      data.to
                        ? data.to.length > 0
                          ? data.to[0].trim().substring(0, 1).toUpperCase()
                          : "EP"
                        : "EP"
                    }}
                  </v-avatar>
                </v-col>
                <v-col cols="12" md="10" lg="11" class="ps-3 ps-md-0">
                  <div class="d-flex">
                    <p class="mb-0">
                      {{ data.subject ? data.subject : "N/A" }}
                    </p>
                    <div class="ms-auto">
                      <v-icon
                        @click="
                          (reply = true),
                            (selectedReply = data),
                            scrollToReply()
                        "
                        class="primary--text pointer"
                        >mdi-reply</v-icon
                      >
                      <v-icon
                        v-if="
                          userDetails && userDetails.userToken.type == 'gmail'
                        "
                        @click="
                          (replyAll = true),
                            (reply = true),
                            (selectedReply = data),
                            scrollToReply()
                        "
                        class="mx-3 primary--text pointer"
                        >mdi-reply-all-outline</v-icon
                      >
                      <v-icon
                        class="primary--text pointer"
                        @click="(forward = !forward), (selectedReply = data)"
                        >mdi-arrow-right</v-icon
                      >
                    </div>
                  </div>
                  <small>{{
                    data.internalDate
                      ? momentTimeDate(data.internalDate)
                      : "N/A"
                  }}</small>
                  <div class="mt-2">
                    <div class="d-flex flex-wrap font-weight-bold">
                      <span>To: </span>
                      <p
                        v-for="(rec, idx) in data.to"
                        :key="idx"
                        class="ml-1 mb-0"
                      >
                        {{ rec }}<span v-if="data.to.length - 1 != idx">;</span>
                      </p>
                    </div>
                    <div
                      class="d-flex flex-wrap font-weight-bold"
                      v-if="data.cc.length > 0"
                    >
                      <span>CC: </span>
                      <p
                        v-for="(rec, idx) in data.cc"
                        :key="idx"
                        class="ml-1 mb-0"
                      >
                        {{
                          rec.EmailAddress
                            ? rec.EmailAddress.Address
                              ? rec.EmailAddress.Address
                              : "N/A"
                            : "N/A"
                        }}<span v-if="data.cc.length - 1 != idx">;</span>
                      </p>
                    </div>
                    <br /><br />
                    <span v-html="data.messageText"></span>
                    <br /><br />
                    <span>From: </span>
                    <span>{{ data.from ? data.from : "N/A" }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div class="email-card" v-if="checkTrash()">
        <div v-if="threadLoader" class="my-5 text-center">
          <v-progress-circular indeterminate size="40" />
        </div>
        <v-expansion-panels class="mt-3" v-model="panel" v-else>
          <v-expansion-panel
            expand
            v-model="panel"
            v-for="(data, idx) in trashThreads"
            :key="idx"
          >
            <v-expansion-panel-header>
              {{ data.subject ? data.subject : "N/A" }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" md="2" lg="1">
                  <v-avatar
                    color="primary"
                    size="40"
                    class="align-center white--text"
                  >
                    {{
                      data.to
                        ? data.to.length > 0
                          ? data.to[0].trim().substring(0, 1).toUpperCase()
                          : "EP"
                        : "EP"
                    }}
                  </v-avatar>
                </v-col>
                <v-col cols="12" md="10" lg="11" class="ps-3 ps-md-0">
                  <div class="d-flex">
                    <p class="mb-0">
                      {{ data.subject ? data.subject : "N/A" }}
                    </p>
                  </div>
                  <small>{{
                    data.internalDate
                      ? momentTimeDate(data.internalDate)
                      : "N/A"
                  }}</small>
                  <div class="mt-2">
                    <div class="d-flex flex-wrap font-weight-bold">
                      <span>To: </span>
                      <p
                        v-for="(rec, idx) in data.to"
                        :key="idx"
                        class="ml-1 mb-0"
                      >
                        {{ rec }}<span v-if="data.to.length - 1 != idx">;</span>
                      </p>
                    </div>
                    <div
                      class="d-flex flex-wrap font-weight-bold"
                      v-if="data.cc.length > 0"
                    >
                      <span>CC: </span>
                      <p
                        v-for="(rec, idx) in data.cc"
                        :key="idx"
                        class="ml-1 mb-0"
                      >
                        {{
                          rec.EmailAddress
                            ? rec.EmailAddress.Address
                              ? rec.EmailAddress.Address
                              : "N/A"
                            : "N/A"
                        }}<span v-if="data.cc.length - 1 != idx">;</span>
                      </p>
                    </div>
                    <br /><br />
                    <span v-html="data.messageText"></span>
                    <br /><br />
                    <span>From: </span>
                    <span>{{ data.from ? data.from : "N/A" }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div class="email-card" v-if="checkArchived()">
        <div v-if="threadLoader" class="my-5 text-center">
          <v-progress-circular indeterminate size="40" />
        </div>
        <v-expansion-panels class="mt-3" v-model="panel" v-else>
          <v-expansion-panel
            expand
            v-model="panel"
            v-for="(data, idx) in archivedThreads"
            :key="idx"
          >
            <v-expansion-panel-header>
              {{ data.subject ? data.subject : "N/A" }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" md="2" lg="1">
                  <v-avatar
                    color="primary"
                    size="40"
                    class="align-center white--text"
                  >
                    {{
                      data.to
                        ? data.to.length > 0
                          ? data.to[0].trim().substring(0, 1).toUpperCase()
                          : "EP"
                        : "EP"
                    }}
                  </v-avatar>
                </v-col>
                <v-col cols="12" md="10" lg="11" class="ps-3 ps-md-0">
                  <div class="d-flex">
                    <p class="mb-0">
                      {{ data.subject ? data.subject : "N/A" }}
                    </p>
                  </div>
                  <small>{{
                    data.internalDate
                      ? momentTimeDate(data.internalDate)
                      : "N/A"
                  }}</small>
                  <div class="mt-2">
                    <div class="d-flex flex-wrap font-weight-bold">
                      <span>To: </span>
                      <p
                        v-for="(rec, idx) in data.to"
                        :key="idx"
                        class="ml-1 mb-0"
                      >
                        {{ rec }}<span v-if="data.to.length - 1 != idx">;</span>
                      </p>
                    </div>
                    <div
                      class="d-flex flex-wrap font-weight-bold"
                      v-if="data.cc.length > 0"
                    >
                      <span>CC: </span>
                      <p
                        v-for="(rec, idx) in data.cc"
                        :key="idx"
                        class="ml-1 mb-0"
                      >
                        {{
                          rec.EmailAddress
                            ? rec.EmailAddress.Address
                              ? rec.EmailAddress.Address
                              : "N/A"
                            : "N/A"
                        }}<span v-if="data.cc.length - 1 != idx">;</span>
                      </p>
                    </div>
                    <br /><br />
                    <span v-html="data.messageText"></span>
                    <br /><br />
                    <span>From: </span>
                    <span>{{ data.from ? data.from : "N/A" }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div class="email-card" v-if="checkContacts()">
        <div v-if="threadLoader" class="my-5 text-center">
          <v-progress-circular indeterminate size="40" />
        </div>
        <v-expansion-panels class="mt-3" v-model="panel" v-else>
          <v-expansion-panel
            expand
            v-model="panel"
            v-for="(data, idx) in contactThreads"
            :key="idx"
          >
            <v-expansion-panel-header>
              {{ data.subject ? data.subject : "N/A" }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" md="2" lg="1">
                  <v-avatar
                    color="primary"
                    size="40"
                    class="align-center white--text"
                  >
                    {{
                      data.to
                        ? data.to.length > 0
                          ? data.to[0].trim().substring(0, 1).toUpperCase()
                          : "EP"
                        : "EP"
                    }}
                  </v-avatar>
                </v-col>
                <v-col cols="12" md="10" lg="11" class="ps-3 ps-md-0">
                  <div class="d-flex">
                    <p class="mb-0">
                      {{ data.subject ? data.subject : "N/A" }}
                    </p>
                  </div>
                  <small>{{
                    data.internalDate
                      ? momentTimeDate(data.internalDate)
                      : "N/A"
                  }}</small>
                  <div class="mt-2">
                    <div class="d-flex flex-wrap font-weight-bold">
                      <span>To: </span>
                      <p
                        v-for="(rec, idx) in data.to"
                        :key="idx"
                        class="ml-1 mb-0"
                      >
                        {{ rec }}<span v-if="data.to.length - 1 != idx">;</span>
                      </p>
                    </div>
                    <div
                      class="d-flex flex-wrap font-weight-bold"
                      v-if="data.cc.length > 0"
                    >
                      <span>CC: </span>
                      <p
                        v-for="(rec, idx) in data.cc"
                        :key="idx"
                        class="ml-1 mb-0"
                      >
                        {{
                          rec.EmailAddress
                            ? rec.EmailAddress.Address
                              ? rec.EmailAddress.Address
                              : "N/A"
                            : "N/A"
                        }}<span v-if="data.cc.length - 1 != idx">;</span>
                      </p>
                    </div>
                    <br /><br />
                    <span v-html="data.messageText"></span>
                    <br /><br />
                    <span>From: </span>
                    <span>{{ data.from ? data.from : "N/A" }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div class="email-card" v-if="checkOpportunity()">
        <div v-if="threadLoader" class="my-5 text-center">
          <v-progress-circular indeterminate size="40" />
        </div>
        <v-expansion-panels class="mt-3" v-model="panel" v-else>
          <v-expansion-panel
            expand
            v-model="panel"
            v-for="(data, idx) in opportunityThreads"
            :key="idx"
          >
            <v-expansion-panel-header>
              {{ data.subject ? data.subject : "N/A" }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" md="2" lg="1">
                  <v-avatar
                    color="primary"
                    size="40"
                    class="align-center white--text"
                  >
                    {{
                      data.to
                        ? data.to.length > 0
                          ? data.to[0].trim().substring(0, 1).toUpperCase()
                          : "EP"
                        : "EP"
                    }}
                  </v-avatar>
                </v-col>
                <v-col cols="12" md="10" lg="11" class="ps-3 ps-md-0">
                  <div class="d-flex">
                    <p class="mb-0">
                      {{ data.subject ? data.subject : "N/A" }}
                    </p>
                  </div>
                  <small>{{
                    data.internalDate
                      ? momentTimeDate(data.internalDate)
                      : "N/A"
                  }}</small>
                  <div class="mt-2">
                    <div class="d-flex flex-wrap font-weight-bold">
                      <span>To: </span>
                      <p
                        v-for="(rec, idx) in data.to"
                        :key="idx"
                        class="ml-1 mb-0"
                      >
                        {{ rec }}<span v-if="data.to.length - 1 != idx">;</span>
                      </p>
                    </div>
                    <div
                      class="d-flex flex-wrap font-weight-bold"
                      v-if="data.cc.length > 0"
                    >
                      <span>CC: </span>
                      <p
                        v-for="(rec, idx) in data.cc"
                        :key="idx"
                        class="ml-1 mb-0"
                      >
                        {{
                          rec.EmailAddress
                            ? rec.EmailAddress.Address
                              ? rec.EmailAddress.Address
                              : "N/A"
                            : "N/A"
                        }}<span v-if="data.cc.length - 1 != idx">;</span>
                      </p>
                    </div>
                    <br /><br />
                    <span v-html="data.messageText"></span>
                    <br /><br />
                    <span>From: </span>
                    <span>{{ data.from ? data.from : "N/A" }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div class="email-card" v-if="checkProject()">
        <div v-if="threadLoader" class="my-5 text-center">
          <v-progress-circular indeterminate size="40" />
        </div>
        <v-expansion-panels class="mt-3" v-model="panel" v-else>
          <v-expansion-panel
            expand
            v-model="panel"
            v-for="(data, idx) in projectThreads"
            :key="idx"
          >
            <v-expansion-panel-header>
              {{ data.subject ? data.subject : "N/A" }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" md="2" lg="1">
                  <v-avatar
                    color="primary"
                    size="40"
                    class="align-center white--text"
                  >
                    {{
                      data.to
                        ? data.to.length > 0
                          ? data.to[0].trim().substring(0, 1).toUpperCase()
                          : "EP"
                        : "EP"
                    }}
                  </v-avatar>
                </v-col>
                <v-col cols="12" md="10" lg="11" class="ps-3 ps-md-0">
                  <div class="d-flex">
                    <p class="mb-0">
                      {{ data.subject ? data.subject : "N/A" }}
                    </p>
                  </div>
                  <small>{{
                    data.internalDate
                      ? momentTimeDate(data.internalDate)
                      : "N/A"
                  }}</small>
                  <div class="mt-2">
                    <div class="d-flex flex-wrap font-weight-bold">
                      <span>To: </span>
                      <p
                        v-for="(rec, idx) in data.to"
                        :key="idx"
                        class="ml-1 mb-0"
                      >
                        {{ rec }}<span v-if="data.to.length - 1 != idx">;</span>
                      </p>
                    </div>
                    <div
                      class="d-flex flex-wrap font-weight-bold"
                      v-if="data.cc.length > 0"
                    >
                      <span>CC: </span>
                      <p
                        v-for="(rec, idx) in data.cc"
                        :key="idx"
                        class="ml-1 mb-0"
                      >
                        {{
                          rec.EmailAddress
                            ? rec.EmailAddress.Address
                              ? rec.EmailAddress.Address
                              : "N/A"
                            : "N/A"
                        }}<span v-if="data.cc.length - 1 != idx">;</span>
                      </p>
                    </div>
                    <br /><br />
                    <span v-html="data.messageText"></span>
                    <br /><br />
                    <span>From: </span>
                    <span>{{ data.from ? data.from : "N/A" }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div class="mt-3" v-if="reply" ref="replyContainer">
        <v-form @submit="send_reply" ref="sendReply">
          <VueEditor v-model="replyMessage" />
          <div class="text-end mt-2">
            <v-btn
              :disabled="replyLoader"
              @click="(reply = !reply), (replyAll = !replyAll)"
              >Cancel</v-btn
            >
            <v-btn
              class="ms-3"
              color="primary"
              :disabled="replyLoader"
              type="submit"
              ><span v-if="!replyLoader">Send</span>
              <v-progress-circular v-else indeterminate size="20"
            /></v-btn>
          </div>
        </v-form>
      </div>
    </v-container>

    <v-dialog v-model="forward" max-width="500">
      <v-card class="card-shadow">
        <v-card-text>
          <div class="d-flex justify-space-between pt-5 pb-3">
            <h2 class="primary--text">Select Recipients</h2>
            <v-icon @click="forward = !forward">mdi-close</v-icon>
          </div>
          <v-divider></v-divider>
          <div class="mt-5">
            <v-form @submit="handleForward" ref="sendForward">
              <v-row>
                <v-col cols="12">
                  <v-label>Recipients</v-label>
                  <v-autocomplete
                    v-model="recipients"
                    :items="contacts"
                    item-text="email"
                    item-value="externalContactId"
                    :rules="[myrules.required]"
                    outlined
                    single-line
                    chips
                    small-chips
                    label="Recipients"
                    multiple
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <div class="text-end">
                <v-btn color="primary" :disabled="forwardLoader" type="submit"
                  ><span v-if="!forwardLoader">Send</span>
                  <v-progress-circular v-else indeterminate size="20"
                /></v-btn>
              </div>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addOpportunity" max-width="500">
      <v-card class="card-shadow">
        <v-card-text>
          <div class="d-flex justify-space-between pt-5 pb-3">
            <h2 class="primary--text">Select Opportunity</h2>
            <v-icon
              @click="
                (addOpportunity = !addOpportunity), (opportunitySearch = '')
              "
              >mdi-close</v-icon
            >
          </div>
          <v-divider></v-divider>
          <div class="mt-5">
            <v-text-field
              label="Search Opportunity"
              outlined
              dense
              v-model="opportunitySearch"
            ></v-text-field>
            <v-list nav dense>
              <div v-if="logsLoader" class="text-center">
                <v-progress-circular
                  v-if="logsLoader"
                  indeterminate
                  size="40"
                />
              </div>
              <v-list-item
                v-else
                link
                active-class="bg-primary primary--text"
                v-for="(oppor, idx) in filteredOpportunity(opportunitySearch)"
                :key="idx"
                @click="opportunityLogs(oppor.opportunityId)"
              >
                <v-list-item-title v-if="!logsLoader">{{
                  oppor.opportunityName ? oppor.opportunityName : "N/A"
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addProject" max-width="500">
      <v-card class="card-shadow">
        <v-card-text>
          <div class="d-flex justify-space-between pt-5 pb-3">
            <h2 class="primary--text">Select Project</h2>
            <v-icon @click="(addProject = !addProject), (projectSearch = '')"
              >mdi-close</v-icon
            >
          </div>
          <v-divider></v-divider>
          <div class="mt-5">
            <v-text-field
              label="Search Projects"
              outlined
              dense
              v-model="projectSearch"
            ></v-text-field>
            <v-list nav dense>
              <div v-if="logsLoader" class="text-center">
                <v-progress-circular
                  v-if="logsLoader"
                  indeterminate
                  size="40"
                />
              </div>
              <v-list-item
                v-else
                link
                active-class="bg-primary primary--text"
                v-for="(project, idx) in filteredProjects(projectSearch)"
                :key="idx"
                @click="projectLogs(project.projectId)"
              >
                <v-list-item-title v-if="!logsLoader">{{
                  project.projectName ? project.projectName : "N/A"
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import { mapActions } from "vuex";
export default {
  components: {
    VueEditor,
  },
  data() {
    return {
      reply: false,
      replyAll: false,
      addOpportunity: false,
      addProject: false,
      opportunitySearch: "",
      projectSearch: "",
      selectedReply: {},
      replyMessage: "",
      forward: false,
      recipients: [],
      panel: 0,
    };
  },

  methods: {
    ...mapActions(["setShowContact", "setNewMail"]),
    scrollToReply() {
      const el = this.$refs.replyContainer;

      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    opportunityLogs(id) {
      this.addOpportunityLogs({
        uid: this.user_id,
        payload: {
          oppurtunity_id: id,
          threadId: this.$route.params.id,
        },
        onSuccess: () => {
          (this.addOpportunity = !this.addOpportunity),
            this.checkLogs({
              id: this.$route.params.id,
            });
        },
      });
    },
    projectLogs(id) {
      this.addProjectLogs({
        uid: this.user_id,
        payload: {
          project_id: id,
          threadId: this.$route.params.id,
        },
        onSuccess: () => {
          (this.addProject = !this.addProject),
            (this.opportunitySearch = ""),
            (this.projectSearch = ""),
            this.checkLogs({
              id: this.$route.params.id,
            });
        },
      });
    },
    send_reply(e) {
      e.preventDefault();
      let validate = this.$refs.sendReply.validate();
      if (validate) {
        if (this.replyAll) {
          let obj = {
            to:
              this.userDetails &&
              this.userDetails.userToken.email ==
                this.filteredInbox().firstMessage.from
                ? this.filteredInbox().firstMessage.to
                : this.filteredInbox().firstMessage.from,
            threadId: this.selectedReply.threadId,
            messageId: this.selectedReply.id,
            messageText: this.replyMessage,
          };
          this.replyToAll({
            uid: this.user_id,
            payload: obj,
            onSuccess: () => {
              this.selectedReply = {};
              this.replyMessage = "";
              this.reply = false;
              this.getInbox(this.user_id);
              this.replyAll = false;
              alert("Reply Sent to All");
            },
          });
        } else {
          if (this.checkInbox()) {
            let obj = {
              to:
                this.userDetails &&
                this.userDetails.userToken.email ==
                  this.filteredInbox().firstMessage.from
                  ? this.filteredInbox().firstMessage.to
                  : this.filteredInbox().firstMessage.from,
              threadId: this.selectedReply.threadId,
              messageId: this.selectedReply.id,
              messageText: this.replyMessage,
            };
            this.replyMail({
              uid: this.user_id,
              payload: obj,
              onSuccess: () => {
                this.selectedReply = {};
                this.replyMessage = "";
                this.reply = false;
                this.replyAll = false;
                this.getInbox(this.user_id);
                alert("Reply Sent");
              },
            });
          } else if (this.checkSent()) {
            let obj = {
              to: this.selectedReply.to,
              threadId: this.selectedReply.threadId,
              messageId: this.selectedReply.id,
              messageText: this.replyMessage,
            };
            this.replyMail({
              uid: this.user_id,
              payload: obj,
              onSuccess: () => {
                this.getThreadMsgs({
                  id: this.user_id,
                  threadId: this.selectedReply.threadId,
                });
                this.selectedReply = {};
                this.replyMessage = "";
                this.reply = false;
                this.replyAll = false;
                this.getSent(this.user_id);
                alert("Reply Sent");
              },
            });
          }
        }
      }
    },
    handleForward(e) {
      e.preventDefault();
      let validate = this.$refs.sendForward.validate();
      // console.log('first', this.selectedReply);
      // console.log('second', this.recipients);
      let tempArr = [];
      this.recipients.forEach((recipient) => {
        let obj = this.contacts.find(
          (contact) => contact.externalContactId == recipient
        );
        if (obj) {
          tempArr.push(obj.email);
        }
      });
      // console.log({ tempArr });
      if (validate && this.recipients.length != 0) {
        let obj = {
          id: this.selectedReply.id,
          to: tempArr.join(),
        };
        this.forwardMail({
          uid: this.user_id,
          payload: obj,
          onSuccess: () => {
            this.selectedReply = {};
            this.recipients = [];
            this.forward = false;
            alert("Email Forward");
          },
        });
      }
    },
    handleOnSuccess() {
      if (this.$route.path.includes("/admin/inbox")) {
        this.$router.push("/admin/inbox");
        this.getInbox(this.user_id);
      }
      if (this.$route.path.includes("/admin/sent")) {
        this.$router.push("/admin/sent");
        this.getSent(this.user_id);
      }
      if (this.$route.path.includes("/admin/trash")) {
        this.$router.push("/admin/trash");
        this.getTrash(this.user_id);
      }
      if (this.$route.path.includes("/admin/archived")) {
        this.$router.push("/admin/archived");
        this.getArchived(this.user_id);
      }
      this.setShowContact(true);
    },
    handleDelete() {
      this.deleteMail({
        id: this.user_id,
        threadId: this.$route.params.id,
        onSuccess: () => {
          this.handleOnSuccess();
        },
      });
    },
    handleMoveToInbox() {
      this.moveToInbox({
        id: this.user_id,
        threadId: this.$route.params.id,
        onSuccess: () => {
          this.handleOnSuccess();
        },
      });
    },
    handleArchive() {
      this.archiveMail({
        id: this.user_id,
        threadId: this.$route.params.id,
        onSuccess: () => {
          this.handleOnSuccess();
        },
      });
    },
    filteredInbox() {
      let obj =
        this.inbox &&
        this.inbox.find((data) => data.id == this.$route.params.id);
      return obj;
    },
    filteredSent() {
      let obj =
        this.sent && this.sent.find((data) => data.id == this.$route.params.id);
      return obj;
    },
    filteredTrash() {
      let obj =
        this.trash &&
        this.trash.find((data) => data.id == this.$route.params.id);
      return obj;
    },
    filteredArchived() {
      let obj =
        this.archived &&
        this.archived.find((data) => data.id == this.$route.params.id);
      return obj;
    },
    filteredContactMessages() {
      let obj =
        this.contactMessages &&
        this.contactMessages.find((data) => data.id == this.$route.params.id);
      return obj;
    },
    filteredLabelMessages() {
      let obj =
        this.labelMessages &&
        this.labelMessages.find((data) => data.id == this.$route.params.id);
      return obj;
    },
    backLogic() {
      this.$router.push("/");
      this.setShowContact(true);
    },
  },

  mounted() {
    this.getContacts(this.user_id);
    this.getProjects(this.user_id);
    this.getOpportunities(this.user_id);
  },
};
</script>
<style scoped>
.w-100 {
  width: 100% !important;
}
.mail-detail-container {
  background: #faf9f8 !important;
  position: relative;
}
.email-card {
  max-height: 580px !important;
  overflow-y: auto;
}
.replycontainer {
  position: absolute;
}
</style>
