<template>
	<div class="logs mt-3">
		<v-container>
			<v-btn small color="black" class="white--text" @click="toContacts"
				>Back</v-btn
			>
			<h2 class="mt-3">Contacts Logs</h2>
			<div class="mt-5">
				<strong>Subject</strong>
				<p>this is subject</p>
			</div>
			<div class="mt-5">
				<strong>Date</strong>
				<p>12-02-2022</p>
			</div>
			<div class="mt-5">
				<strong>Body</strong>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
					do eiusmod tempor incididunt ut labore et dolore magna
					aliqua. Ut enim ad minim veniam, quis nostrud exercitation
					ullamco laboris nisi ut aliquip ex ea commodo consequat.
					Duis aute irure dolor in reprehenderit in voluptate velit
					esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
					occaecat cupidatat non proident, sunt in culpa qui officia
					deserunt mollit anim id est laborum.
				</p>
			</div>
		</v-container>
	</div>
</template>
<script>
	export default {
		methods: {
			toContacts() {
				this.$router.push('/admin/contacts');
			},
		},
	};
</script>
<style scoped></style>
