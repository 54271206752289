import Vue from 'vue';
import VueRouter from 'vue-router';
import DashboardLayout from '../components/DashboardLayout.vue';
import Contacts from '../views/Dashboard/Contacts.vue';
import SendMail from '../views/Dashboard/SendMail.vue';
import Settings from '../views/Dashboard/Settings.vue';
import Logs from '../views/Dashboard/Logs.vue';
import Email from '../views/Dashboard/Email.vue';
import Inbox from '../views/Dashboard/Email/Inbox.vue';
import MailDetails from '../views/Dashboard/Email/MailDetails.vue';
import Sent from '../views/Dashboard/Email/Sent.vue';
import Trash from '../views/Dashboard/Email/Trash.vue';
import Archived from '../views/Dashboard/Email/Archived.vue';
import Opportunities from '../views/Dashboard/Opportunities.vue';
import Projects from '../views/Dashboard/Projects.vue';

Vue.use(VueRouter);

const routes = [
	{
		path: '/admin',
		name: 'Dashboard',
		component: DashboardLayout,
		props: true,
		meta: {
			requiresAuth: true,
		},
		children: [
			{
				path: 'email',
				component: Email,
				name: 'Email',
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: 'inbox',
				component: Inbox,
				name: 'Inbox',
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: 'inbox/:id',
				component: MailDetails,
				name: 'MailDetails',
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: 'sent',
				component: Sent,
				name: 'Sent',
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: 'sent/:id',
				component: MailDetails,
				name: 'MailDetails',
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: 'trash',
				component: Trash,
				name: 'Trash',
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: 'trash/:id',
				component: MailDetails,
				name: 'MailDetails',
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: 'archived',
				component: Archived,
				name: 'Archived',
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: 'archived/:id',
				component: MailDetails,
				name: 'MailDetails',
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: 'contacts',
				component: Contacts,
				name: 'Contacts',
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: 'contacts/:id',
				component: MailDetails,
				name: 'MailDetails',
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: 'opportunities',
				component: Opportunities,
				name: 'Opportunities',
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: 'opportunities/:id',
				component: MailDetails,
				name: 'MailDetails',
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: 'projects',
				component: Projects,
				name: 'Projects',
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: 'projects/:id',
				component: MailDetails,
				name: 'MailDetails',
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: 'settings',
				component: Settings,
				name: 'Settings',
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: 'send-mail',
				component: SendMail,
				name: 'Send Mail',
				meta: {
					requiresAuth: true,
				},
			},

			{
				path: 'logs',
				component: Logs,
				name: 'Logs',
				meta: {
					requiresAuth: true,
				},
			},
		],
	},
	{
		path: '/',
		name: 'Dashboard',
		component: DashboardLayout,
		props: true,
		meta: {
			requiresAuth: true,
		},
	},
];

const router = new VueRouter({
	mode: 'history',
	// base: process.env.BASE_URL,
	routes: routes,
});

export default router;
