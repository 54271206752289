<template>
	<v-app-bar absolute color="grey lighten-4" flat>
		<v-app-bar-nav-icon @click="toggleSidebar"></v-app-bar-nav-icon>

		<v-toolbar-title>Dashboard</v-toolbar-title>

		<v-spacer></v-spacer>

		<!-- <v-btn text><v-icon>mdi-power</v-icon> Logout</v-btn> -->
	</v-app-bar>
</template>

<script>
	export default {
		data() {
			return {
				show: true,
			};
		},

		methods: {
			toggleSidebar() {
				this.show = !this.show;
				this.$emit('toggle', this.show);
			},
		},
	};
</script>
