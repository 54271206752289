import Repository from './Repository';
const GET_USER = '/api/getUser';
const GMAIL = '/gmail';
const INBOX = 'inbox';
const MOVE_TO_INBOX = 'moveToInbox';
const MARK_AS_READ = 'markAsRead';
const FORWARD = 'forward';
const REPLY = 'reply';
const REPLY_TO_ALL = 'replyToAll';
const ALL = 'all';
const DELETE = 'delete';
const CLEAR_TRASH = 'clearTrash';
const ARCHIVE = 'archive';
const SEND = 'send';
const SEARCH = 'search';
const MESSAGE = 'message';
const SORT_BY_ORDER = 'sortByOrder';
const CHECK_LOG = 'checkLog';
const ADD_LOGS = 'addLogs';
const DISCONNECT = '/api/disconnect';
const GET_THREAD_MESSAGES = 'getThreadMessages';
const ADD_TO_DB = 'addToDB';
export default {
	getUserDetails(payload) {
		return Repository.get(`${GET_USER}/${payload.id}`, {});
	},
	disconnectMail(payload) {
		return Repository.get(`${DISCONNECT}/${payload.id}`, {});
	},

	getInbox(payload) {
		return Repository.get(`${GMAIL}/${INBOX}/${payload.id}`, {});
	},
	addToDB(payload) {
		return Repository.post(`${GMAIL}/${ADD_TO_DB}/${payload.id}`, {
			threadId: payload.threadId,
		});
	},
	getThreadMsgs(payload) {
		return Repository.post(
			`${GMAIL}/${GET_THREAD_MESSAGES}/${payload.id}`,
			{ threadId: payload.threadId }
		);
	},
	moveToInbox({ id, threadId }) {
		return Repository.post(`${GMAIL}/${MOVE_TO_INBOX}/${id}`, {
			threadId,
		});
	},
	getAll(payload) {
		return Repository.get(
			`${GMAIL}/${ALL}/${payload.id}?label=${payload.label}`,
			{}
		);
	},
	deleteMail(payload) {
		return Repository.post(`${GMAIL}/${DELETE}/${payload.id}`, {
			threadId: payload.threadId,
		});
	},
	clearTrash(payload) {
		return Repository.get(`${GMAIL}/${CLEAR_TRASH}/${payload.id}`, {});
	},
	archiveMail(payload) {
		return Repository.post(`${GMAIL}/${ARCHIVE}/${payload.id}`, {
			threadId: payload.threadId,
		});
	},
	forwardMail({ userId, payload }) {
		return Repository.post(`${GMAIL}/${FORWARD}/${userId}`, {
			id: payload.payload.id,
			to: payload.payload.to,
		});
	},
	replyMail({ payload, userId }) {
		return Repository.post(`${GMAIL}/${REPLY}/${userId}`, payload.payload);
	},
	replyToAll({ payload, userId }) {
		return Repository.post(
			`${GMAIL}/${REPLY_TO_ALL}/${userId}`,
			payload.payload
		);
	},
	sendNewMail({ userId, payload }) {
		console.log({ payload });
		let form = new FormData();
		form.append(
			'recipient_emails',
			JSON.stringify(payload.recipient_emails)
		);
		form.append('subject', payload.subject);
		form.append('message', payload.message);
		form.append('attachment', payload.attachment);
		form.append('oppurtunity_id', payload.oppurtunity_id);
		form.append('project_id', payload.project_id);
		form.append('cc', payload.cc);
		return Repository.post(`${GMAIL}/${SEND}/${userId}`, form);
	},
	searchInbox(payload) {
		return Repository.get(
			`${GMAIL}/${INBOX}/${payload.id}?${SEARCH}=${payload.message}`,
			{}
		);
	},
	unreadInbox(payload) {
		return Repository.get(
			`${GMAIL}/${INBOX}/${payload.id}?${MESSAGE}=${payload.message}`,
			{}
		);
	},
	orderInbox(payload) {
		return Repository.get(
			`${GMAIL}/${INBOX}/${payload.id}?${SORT_BY_ORDER}=${payload.message}`,
			{}
		);
	},
	searchAll(payload) {
		return Repository.get(
			`${GMAIL}/${ALL}/${payload.id}?label=${payload.label}&${SEARCH}=${payload.message}`,
			{}
		);
	},
	markAsRead({ id, threadId }) {
		return Repository.post(`${GMAIL}/${MARK_AS_READ}/${id}`, {
			threadId,
		});
	},
	checkLogs({ id }) {
		return Repository.get(`${GMAIL}/${CHECK_LOG}/${id}`, {});
	},
	addOpportunityLogs({ userId, payload }) {
		return Repository.post(`${GMAIL}/${ADD_LOGS}/${userId}`, {
			threadId: payload.threadId,
			oppurtunity_id: payload.oppurtunity_id,
		});
	},
	addProjectLogs({ userId, payload }) {
		return Repository.post(`${GMAIL}/${ADD_LOGS}/${userId}`, {
			threadId: payload.threadId,
			project_id: payload.project_id,
		});
	},
};
