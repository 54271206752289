<template>
	<div class="settings mt-15">
		<div v-if="user_id">
			<v-toolbar>
				<v-tabs dark background-color="secondary" grow>
					<v-tab @click="active = 'pref'"> Email Preference </v-tab>

					<v-tab
						v-if="userDetails && userDetails.userToken"
						@click="(active = 'sign'), getSignatures(user_id)"
					>
						Email Signature
					</v-tab>
				</v-tabs>
			</v-toolbar>
			<v-container v-if="active == 'pref'" class="mt-10">
				<div v-if="userDetails && !userDetails.userToken">
					<h2 class="text-center">Choose your email provider</h2>
					<div class="d-flex justify-center mt-5">
						<form
							:action="API_ENDPOINT + 'auth/google'"
							method="GET"
						>
							<input
								type="hidden"
								name="userId"
								:value="user_id"
							/>
							<!-- <v-btn color="primary" type="submit"
							>Connect Gmail</v-btn
						> -->
							<button
								class="provider pointer text-center"
								type="btn"
								disabled
							>
								<img
									:src="GLogo"
									alt="Google"
									class="img-fluid"
									height="64px"
								/>
								<br />
								<strong>Google / Gmail</strong>
							</button>
						</form>
						<form
							:action="API_ENDPOINT + 'auth/outlook'"
							method="GET"
						>
							<input
								type="hidden"
								name="userId"
								:value="user_id"
							/>
							<!-- <v-btn color="primary" type="submit"
							>Connect Gmail</v-btn
						> -->
							<button
								class="provider ml-3 pointer text-center"
								type="submit"
							>
								<img
									:src="OLogo"
									alt="Outlook"
									class="img-fluid"
									height="64px"
								/>
								<br />
								<strong>Outlook</strong>
							</button>
						</form>
					</div>
				</div>
				<div class="mt-5 text-center" v-else>
					<p>
						You have already connected your
						<strong
							>{{
								userDetails &&
								userDetails.userToken.type == 'gmail'
									? 'Gmail'
									: 'Outlook'
							}}
							Account</strong
						>
						with
						<strong>{{
							userDetails && userDetails.userToken.email
								? userDetails.userToken.email
								: 'N/A'
						}}</strong>
					</p>
					<v-btn
						color="error"
						@click="
							disconnectMail({
								id: user_id,
								onSuccess: () => {
									getUsersDetails(user_id);
								},
							})
						"
						:disabled="disconnectLoader"
					>
						<span v-if="!disconnectLoader">Disconnect</span>
						<v-progress-circular v-else indeterminate size="20" />
					</v-btn>
				</div>
			</v-container>
			<v-container class="mt-10" v-if="active == 'sign'">
				<h2 class="mb-2">Compose and reply</h2>
				<hr />
				<h4 class="mt-10 mb-5">Email Signature</h4>
				<strong>Create and Edit Signature</strong>
				<br />
				<strong
					class="primary--text pointer"
					v-if="!mySignature"
					@click="newSignature = true"
				>
					<v-icon color="primary">mdi-plus</v-icon> New Signature
				</strong>
				<div class="d-flex mt-8" v-if="mySignature">
					<div class="signature">
						<strong v-html="mySignature.signatureText"></strong>
					</div>
					<v-btn
						outlined
						color="primary"
						class="pa-6 ms-3"
						@click="newUpdateSignature = true"
						>Edit</v-btn
					>
					<v-btn
						outlined
						color="error"
						class="pa-6 ms-3"
						@click="
							deleteSignature({
								id: user_id,
								onSuccess: () => {
									getSignatures(user_id);
								},
							})
						"
					>
						<span v-if="!createSignatureLoader">Delete</span>
						<v-progress-circular v-else indeterminate size="20" />
					</v-btn>
				</div>
				<div v-if="newSignature">
					<v-form
						@submit="handleSignatureSubmit"
						ref="newSignatureSubmit"
					>
						<VueEditor v-model="signature" class="mt-8" />
						<div class="text-right mt-2">
							<v-btn
								color="secondary"
								class="me-2"
								@click="newSignature = false"
								>Cancel</v-btn
							>
							<v-btn
								type="submit"
								:disabled="createSignatureLoader || !signature"
								color="primary"
								><span v-if="!createSignatureLoader">Save</span>
								<v-progress-circular
									v-else
									indeterminate
									size="20"
								/>
							</v-btn>
						</div>
					</v-form>
				</div>
				<div v-if="newUpdateSignature">
					<v-form
						@submit="handleSignatureEdit"
						ref="newSignatureEdit"
					>
						<VueEditor v-model="updatedSignature" class="mt-8" />
						<div class="text-right mt-2">
							<v-btn
								color="secondary"
								class="me-2"
								@click="newUpdateSignature = false"
								>Cancel</v-btn
							>
							<v-btn
								type="submit"
								:disabled="
									createSignatureLoader || !newUpdateSignature
								"
								color="primary"
								><span v-if="!createSignatureLoader"
									>Update</span
								>
								<v-progress-circular
									v-else
									indeterminate
									size="20"
								/>
							</v-btn>
						</div>
					</v-form>
				</div>
			</v-container>
			<!-- Outlook Connect -->
			<v-dialog v-model="outlookOpen" max-width="500">
				<v-card class="card-shadow">
					<v-card-text>
						<div class="d-flex justify-space-between pt-5 pb-3">
							<h2 class="primary--text">
								Connect Outlook Account
							</h2>
							<v-icon @click="outlookOpen = !outlookOpen"
								>mdi-close</v-icon
							>
						</div>
						<v-divider></v-divider>
						<div class="mt-5">
							<v-label>Email</v-label>
							<v-text-field
								type="email"
								hint="Use your Outlook email"
								outlined
								single-line
							>
							</v-text-field>
							<v-label>Password</v-label>
							<v-text-field
								type="password"
								hint="Use your Outlook password"
								outlined
								single-line
							>
							</v-text-field>
						</div>
						<div class="text-right mt-2 mb-2">
							<v-btn
								color="error"
								class="py-6"
								@click="outlookOpen = !outlookOpen"
								>Close</v-btn
							>
							<v-btn color="primary" class="py-6 ml-2"
								>Connect</v-btn
							>
						</div>
					</v-card-text>
				</v-card>
			</v-dialog>
			<!-- Gmail Connect -->
			<v-dialog v-model="gmailOpen" max-width="500">
				<v-card class="card-shadow">
					<v-card-text>
						<div class="d-flex justify-space-between pt-5 pb-3">
							<h2 class="primary--text">Connect Gmail Account</h2>
							<v-icon
								@click="(gmailOpen = !gmailOpen), (step = 1)"
								>mdi-close</v-icon
							>
						</div>
						<v-divider></v-divider>
						<div class="mt-5">
							<strong class="black--text" v-if="step < 7"
								>Instructions for creating Gmail
								Application-Specific Password</strong
							>
							<div class="d-flex mt-3">
								<v-btn
									small
									color="secondary"
									@click="step = step - 1"
									v-if="step > 1"
									>Prev</v-btn
								>
								<v-btn
									v-if="step < 7"
									@click="step = step + 1"
									small
									color="primary"
									class="ml-auto"
									>Next</v-btn
								>
							</div>
							<div class="mt-5 black--text">
								<div v-if="step == 1">
									<p>
										<strong>1. </strong>
										Select your
										<strong>profile</strong> icon in the
										upper-right corner of Gmail, then select
										<strong>Manage Google Account.</strong>
									</p>
									<img :src="Step1" width="100%" alt="" />
									<div class="text-right mt-3">
										<v-btn
											small
											color="info"
											@click="step = 7"
											v-if="step < 7"
											>Skip</v-btn
										>
									</div>
								</div>
								<div v-if="step == 2">
									<p>
										<strong>2. </strong>
										Select <strong>Security</strong> in the
										left sidebar.
									</p>
									<img :src="Step2" width="100%" alt="" />
									<div class="text-right mt-3">
										<v-btn
											small
											color="info"
											@click="step = 7"
											v-if="step < 7"
											>Skip</v-btn
										>
									</div>
								</div>
								<div v-if="step == 3">
									<p>
										<strong>3. </strong>
										Select
										<strong>App passwords</strong> under the
										<strong>Signing into Google</strong>
										section. You're then asked to confirm
										your Gmail login credentials.
									</p>
									<img :src="Step3" width="100%" alt="" />
									<div class="text-right mt-3">
										<v-btn
											small
											color="info"
											@click="step = 7"
											v-if="step < 7"
											>Skip</v-btn
										>
									</div>
								</div>
								<div v-if="step == 4">
									<p>
										<strong>4. </strong>
										Under <strong>Select app</strong>,
										choose <strong>Mail</strong> or
										<strong>Other (Custom name),</strong>
										then select a device.
									</p>
									<img :src="Step4" width="100%" alt="" />
									<div class="text-right mt-3">
										<v-btn
											small
											color="info"
											@click="step = 7"
											v-if="step < 7"
											>Skip</v-btn
										>
									</div>
								</div>
								<div v-if="step == 5">
									<p>
										<strong>5. </strong>
										Select <strong>Generate.</strong>
									</p>
									<img :src="Step5" width="100%" alt="" />
									<div class="text-right mt-3">
										<v-btn
											small
											color="info"
											@click="step = 7"
											v-if="step < 7"
											>Skip</v-btn
										>
									</div>
								</div>
								<div v-if="step == 6">
									<p>
										<strong>6. </strong>
										Your password appears in a new window.
										Follow the on-screen instructions to
										complete the process, then select
										<strong>Done.</strong>
									</p>
									<img :src="Step6" width="100%" alt="" />
									<div class="text-right mt-3">
										<v-btn
											small
											color="info"
											@click="step = 7"
											v-if="step < 7"
											>Skip</v-btn
										>
									</div>
								</div>
								<div v-if="step == 7">
									<div class="mt-5">
										<v-label>Email</v-label>
										<v-text-field
											type="email"
											outlined
											single-line
										>
										</v-text-field>
										<v-label>Password</v-label>
										<v-text-field
											type="password"
											outlined
											single-line
										>
										</v-text-field>
										<v-label>App Password</v-label>
										<v-text-field
											type="password"
											outlined
											single-line
										>
										</v-text-field>
									</div>
									<div class="text-right mt-2 mb-2">
										<v-btn
											color="error"
											class="py-6"
											@click="
												(gmailOpen = !gmailOpen),
													(step = 1)
											"
											>Close</v-btn
										>
										<v-btn color="primary" class="py-6 ml-2"
											>Connect</v-btn
										>
									</div>
								</div>
							</div>
						</div>
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>
		<div class="text-center" v-else>
			<strong>No User Found, Please Do Visit Us Again!</strong>
		</div>
	</div>
</template>
<script>
	import { VueEditor } from 'vue2-editor';
	import Step1 from '../../assets/step1.png';
	import Step2 from '../../assets/step2.png';
	import Step3 from '../../assets/step3.png';
	import Step4 from '../../assets/step4.png';
	import Step5 from '../../assets/step5.png';
	import Step6 from '../../assets/step6.png';
	import GLogo from '../../assets/g-logo.png';
	import OLogo from '../../assets/o-logo.png';
	export default {
		components: {
			VueEditor,
		},
		data() {
			return {
				active: 'pref',
				signature: '',
				updatedSignature: '',
				newSignature: false,
				newUpdateSignature: false,
				outlookOpen: false,
				gmailOpen: false,
				step: 1,
				Step1: Step1,
				Step2: Step2,
				Step3: Step3,
				Step4: Step4,
				Step5: Step5,
				Step6: Step6,
				GLogo: GLogo,
				OLogo: OLogo,
			};
		},
		watch: {
			mySignature: function () {
				this.updatedSignature = this.mySignature.signatureText;
			},
		},
		mounted() {
			this.getUsersDetails(this.user_id);
		},
		methods: {
			handleSignatureSubmit(e) {
				e.preventDefault();
				let validate = this.$refs.newSignatureSubmit.validate();
				if (validate) {
					this.createSignature({
						id: this.user_id,
						text: this.signature,
						onSuccess: () => {
							(this.newSignature = false), (this.signature = '');
							this.getSignatures(this.user_id);
						},
					});
				}
			},
			handleSignatureEdit(e) {
				e.preventDefault();
				let validate = this.$refs.newSignatureEdit.validate();
				if (validate) {
					this.updateSignature({
						id: this.user_id,
						text: this.updatedSignature,
						onSuccess: () => {
							this.newUpdateSignature = false;
							this.getSignatures(this.user_id);
						},
					});
				}
			},
		},
	};
</script>
<style scoped>
	.provider {
		height: 130px !important;
		width: 180px !important;
		background: transparent !important;
		box-shadow: none !important;
		border: 1px solid rgb(133, 198, 252);
	}
	.signature {
		padding: 10px;
		border: 1px solid rgb(133, 198, 252);
		border-radius: 4px;
		width: 100% !important;
	}
</style>
