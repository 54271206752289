import Vue from 'vue';
import Vuex from 'vuex';
import Layout from './modules/Layout';
import Gmail from './modules/Gmail';
import General from './modules/General';
Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		Gmail,
		Layout,
		General,
	},
});
