let state = {
	showContact: true,
	newMail: false,
};
let getters = {
	showContact: state => state.showContact,
	newMail: state => state.newMail,
};
let actions = {
	async setShowContact({ commit }, payload) {
		commit('setShowContact', payload);
	},
	async setNewMail({ commit }, payload) {
		commit('setNewMail', payload);
	},
};
let mutations = {
	setShowContact(state, payload) {
		state.showContact = payload;
	},
	setNewMail(state, payload) {
		state.newMail = payload;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
