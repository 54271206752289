import { RepositoryFactory } from '../../repository/RepositoryFactory';
var General = RepositoryFactory.get('general');
let loggedUser = localStorage.getItem('user_id');
let state = {
	user_id: loggedUser,
	validationLoader: false,
	isValid: false,
	projects: [],
	projectLoader: false,
	opportunities: [],
	opportunityLoader: false,
	contacts: [],
	contactsLoader: false,
	contactMessages: [],
	contactMessageLoader: false,
	labelMessages: [],
	mySignature: {},
	labelMessageLoader: false,
	createSignatureLoader: false,
};
let getters = {
	user_id: state => state.user_id,
	validationLoader: state => state.validationLoader,
	isValid: state => state.isValid,
	projects: state => state.projects,
	projectLoader: state => state.projectLoader,
	opportunities: state => state.opportunities,
	opportunityLoader: state => state.opportunityLoader,
	contacts: state => state.contacts,
	contactsLoader: state => state.contactsLoader,
	contactMessages: state => state.contactMessages,
	contactMessageLoader: state => state.contactMessageLoader,
	labelMessages: state => state.labelMessages,
	labelMessageLoader: state => state.labelMessageLoader,
	mySignature: state => state.mySignature,
	createSignatureLoader: state => state.createSignatureLoader,
};
let actions = {
	async checkValidUID({ commit }, payload) {
		commit('setValidationLoader', true);
		let onSuccess = payload['onSuccess'] ? payload['onSuccess'] : () => {};
		try {
			let { data } = await General.checkValidUID({ id: payload.id });
			// console.log({ data });
			if (data.success) {
				commit('setIsValid', data.success);
				commit('setValidationLoader', false);
				onSuccess();
			} else {
				commit('setIsValid', false);
				// alert('User ID is not valid, Kindly Revisit.');
				commit('setValidationLoader', false);
				onSuccess();
			}
		} catch (e) {
			alert(e.response.data.message);
			commit('setValidationLoader', false);
		}
	},
	async fillUserID({ commit }, payload) {
		commit('setUserID', payload);
	},
	async getProjects({ commit }, payload) {
		commit('setProjectsLoader', true);
		try {
			let { data } = await General.getProjects({ id: payload });
			// console.log({ data });
			if (data.success) {
				commit('setProjects', data.data);
				commit('setProjectsLoader', false);
			} else {
				alert(data.message);
				commit('setProjectsLoader', false);
			}
		} catch (e) {
			alert(e.response.data.message);
			commit('setProjectsLoader', false);
		}
	},
	async getOpportunities({ commit }, payload) {
		commit('setOpportunityLoader', true);
		try {
			let { data } = await General.getOpportunities({ id: payload });
			// console.log({ data });
			if (data.success) {
				commit('setOpportunities', data.data);
				commit('setOpportunityLoader', false);
			} else {
				alert(data.message);
				commit('setOpportunityLoader', false);
			}
		} catch (e) {
			alert(e.response.data.message);
			commit('setOpportunityLoader', false);
		}
	},
	async getContacts({ commit }, payload) {
		commit('setContactsLoader', true);
		try {
			let { data } = await General.getContacts({ id: payload });
			// console.log({ data });
			if (data.success) {
				commit('setContacts', data.data);
				commit('setContactsLoader', false);
			} else {
				alert(data.message);
				commit('setContactsLoader', false);
			}
		} catch (e) {
			alert(e.response.data.message);
			commit('setContactsLoader', false);
		}
	},
	async getContactMessages({ commit }, payload) {
		commit('setContactMessageLoader', true);
		try {
			let { data } = await General.getContactMessages(payload);
			// console.log({ data });
			if (data.success) {
				commit('setContactMessages', data.data);
				commit('setContactMessageLoader', false);
			} else {
				alert(data.message);
				commit('setContactMessageLoader', false);
			}
		} catch (e) {
			alert(e.response.data.message);
			commit('setContactMessageLoader', false);
		}
	},
	async getLabelMessages({ commit }, payload) {
		commit('setLabelMessageLoader', true);
		try {
			let { data } = await General.getLabelMessages({
				id: payload.uid,
				payload,
			});
			// console.log({ data });
			if (data.success) {
				commit('setLabelMessages', data.data);
				commit('setLabelMessageLoader', false);
			} else {
				alert(data.message);
				commit('setLabelMessageLoader', false);
			}
		} catch (e) {
			alert(e.response.data.message);
			commit('setLabelMessageLoader', false);
		}
	},
	async createSignature({ commit }, payload) {
		commit('setCreateSignatureLoader', true);
		let onSuccess = payload['onSuccess'] ? payload['onSuccess'] : () => {};
		try {
			let { data } = await General.createSignature({
				id: payload.id,
				text: payload.text,
			});
			if (data.success) {
				onSuccess();
				commit('setCreateSignatureLoader', false);
			} else {
				commit('setCreateSignatureLoader', false);
			}
		} catch (e) {
			alert(e.response.data.message);
			commit('setCreateSignatureLoader', false);
		}
	},
	async updateSignature({ commit }, payload) {
		commit('setCreateSignatureLoader', true);
		let onSuccess = payload['onSuccess'] ? payload['onSuccess'] : () => {};
		try {
			let { data } = await General.updateSignature({
				id: payload.id,
				text: payload.text,
			});
			if (data.success) {
				onSuccess();
				commit('setCreateSignatureLoader', false);
			} else {
				commit('setCreateSignatureLoader', false);
			}
		} catch (e) {
			alert(e.response.data.message);
			commit('setCreateSignatureLoader', false);
		}
	},
	async getSignatures({ commit }, payload) {
		commit('setCreateSignatureLoader', true);
		try {
			let { data } = await General.getSignatures({
				id: payload,
			});
			if (data.success) {
				commit('setMySignatures', data.data);
				commit('setCreateSignatureLoader', false);
			} else {
				commit('setCreateSignatureLoader', false);
			}
		} catch (e) {
			alert(e.response.data.message);
			commit('setCreateSignatureLoader', false);
		}
	},
	async deleteSignature({ commit }, payload) {
		commit('setCreateSignatureLoader', true);
		let onSuccess = payload['onSuccess'] ? payload['onSuccess'] : () => {};
		try {
			let { data } = await General.deleteSignature({
				id: payload.id,
			});
			if (data.success) {
				onSuccess();
				commit('setCreateSignatureLoader', false);
			} else {
				commit('setCreateSignatureLoader', false);
			}
		} catch (e) {
			alert(e.response.data.message);
			commit('setCreateSignatureLoader', false);
		}
	},
	async clearMessages({ commit }) {
		commit('setClearMessages');
	},
};
let mutations = {
	setIsValid(state, payload) {
		state.isValid = payload;
	},
	setValidationLoader(state, payload) {
		state.validationLoader = payload;
	},
	setUserID(state, payload) {
		state.user_id = payload;
	},
	setProjects(state, payload) {
		state.projects = payload;
	},
	setProjectsLoader(state, payload) {
		state.projectLoader = payload;
	},
	setOpportunities(state, payload) {
		state.opportunities = payload;
	},
	setOpportunityLoader(state, payload) {
		state.opportunityLoader = payload;
	},
	setContacts(state, payload) {
		state.contacts = payload;
	},
	setContactsLoader(state, payload) {
		state.contactsLoader = payload;
	},
	setContactMessages(state, payload) {
		state.contactMessages = payload;
	},
	setContactMessageLoader(state, payload) {
		state.contactMessageLoader = payload;
	},
	setLabelMessages(state, payload) {
		state.labelMessages = payload;
	},
	setLabelMessageLoader(state, payload) {
		state.labelMessageLoader = payload;
	},
	setCreateSignatureLoader(state, payload) {
		state.createSignatureLoader = payload;
	},
	setMySignatures(state, payload) {
		state.mySignature = payload;
	},
	setClearMessages(state, payload) {
		state.contactMessages = [];
		state.labelMessages = [];
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
