<template>
  <div class="dashboard-layout">
    <v-navigation-drawer
      app
      permanent
      v-if="toggle"
      color="white"
      class="card-shadow sidebar"
    >
      <v-overlay :value="validationLoader"> </v-overlay>
      <v-list class="sidebar_heading_component">
        <div class="text-center dashboard-logo fs-20 font-500">
          <v-btn
            v-if="userDetails && userDetails.userToken"
            color="secondary"
            @click="setNewMail(true), setShowContact(false)"
            >New Email</v-btn
          >
        </div>
      </v-list>
      <!-- <v-divider></v-divider> -->

      <v-list nav dense>
        <div v-if="userDetails && userDetails.userToken">
          <v-list-group
            :value="activeChild.email"
            @click="routeEmail"
            prepend-icon="mdi-email"
          >
            <template v-slot:activator>
              <v-list-item-title>Email</v-list-item-title>
            </template>
            <v-list-item
              @click="routeInbox"
              active-class="bg-primary primary--text"
            >
              <v-list-item-icon>
                <v-icon></v-icon>
              </v-list-item-icon>
              <v-list-item-title>Inbox</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="routeSent"
              active-class="bg-primary primary--text"
            >
              <v-list-item-icon>
                <v-icon></v-icon>
              </v-list-item-icon>
              <v-list-item-title>Sent</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="routeTrash"
              active-class="bg-primary primary--text"
            >
              <v-list-item-icon>
                <v-icon></v-icon>
              </v-list-item-icon>
              <v-list-item-title>Trash</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="routeArchived"
              active-class="bg-primary primary--text"
            >
              <v-list-item-icon>
                <v-icon></v-icon>
              </v-list-item-icon>
              <v-list-item-title>Archived</v-list-item-title>
            </v-list-item>
          </v-list-group>
          <v-list-group
            :value="activeChild.contacts"
            @click="routeContact"
            prepend-icon="mdi-contacts"
          >
            <template v-slot:activator>
              <v-list-item-title>Contacts</v-list-item-title>
            </template>
            <v-text-field
              hide-details
              v-model="searchContact"
              class="mt-2 mb-5"
              dense
              outlined
              rounded
              single-line
              label="Search Contact"
              append-icon="mdi-magnify"
            ></v-text-field>
            <v-list class="contact-list">
              <v-list-item
                v-for="(cntct, idx) in filteredContacts()"
                :key="idx"
                active-class="bg-primary primary--text "
                class="mb-5"
              >
                <v-list-item
                  @click="
                    getContactMessages({
                      id: user_id,
                      contactEmail: cntct.email,
                    })
                  "
                  active-class="bg-primary primary--text"
                >
                  <v-avatar class="white--text" size="32" color="primary">
                    {{
                      cntct.externalContactName
                        ? cntct.externalContactName.trim().substring(0, 1)
                        : "EP"
                    }}
                  </v-avatar>
                  <v-list-item-title class="ms-3"
                    ><span>{{
                      cntct.externalContactName
                        ? cntct.externalContactName
                        : "N/A"
                    }}</span>
                    <br />
                    <small>{{
                      cntct.email ? cntct.email : "N/A"
                    }}</small></v-list-item-title
                  >
                </v-list-item>
              </v-list-item>
            </v-list>
          </v-list-group>
          <v-list-group
            :value="activeChild.opportunities"
            @click="routeOpportunities"
            prepend-icon="mdi-filter-plus-outline"
          >
            <template v-slot:activator>
              <v-list-item-title>Opportunities</v-list-item-title>
            </template>
            <v-text-field
              hide-details
              class="mt-2 mb-3"
              dense
              outlined
              rounded
              single-line
              v-model="searchOpportunity"
              label="Search Oppottunities"
              append-icon="mdi-magnify"
            ></v-text-field>
            <v-list class="contact-list">
              <v-list-item
                v-for="(oppor, idx) in filteredOpportunity(searchOpportunity)"
                :key="idx"
                active-class="bg-primary primary--text "
                class="mb-5"
              >
                <v-list-item
                  @click="
                    getLabelMessages({
                      uid: user_id,
                      id: oppor.opportunityId,
                      label: 'oppurtunity',
                    })
                  "
                  active-class="bg-primary primary--text"
                >
                  <v-avatar class="white--text" size="32" color="primary">
                    {{
                      oppor.opportunityName
                        ? oppor.opportunityName.trim().substring(0, 1)
                        : "EP"
                    }}
                  </v-avatar>
                  <v-list-item-title class="ms-3"
                    ><span>{{
                      oppor.opportunityName ? oppor.opportunityName : "N/A"
                    }}</span></v-list-item-title
                  >
                </v-list-item>
              </v-list-item>
            </v-list>
          </v-list-group>
          <v-list-group
            :value="activeChild.projects"
            @click="routeProject"
            prepend-icon="mdi-trophy-outline"
          >
            <template v-slot:activator>
              <v-list-item-title>Projects</v-list-item-title>
            </template>
            <v-text-field
              class="mt-2 mb-3"
              hide-details
              dense
              outlined
              rounded
              single-line
              label="Search Projects"
              append-icon="mdi-magnify"
              v-model="searchProjects"
            ></v-text-field>
            <v-list class="contact-list">
              <v-list-item
                v-for="(project, idx) in filteredProjects(searchProjects)"
                :key="idx"
                active-class="bg-primary primary--text "
                class="mb-5"
              >
                <v-list-item
                  @click="
                    getLabelMessages({
                      uid: user_id,
                      id: project.projectId,
                      label: 'project',
                    })
                  "
                  active-class="bg-primary primary--text"
                >
                  <v-avatar class="white--text" size="32" color="primary">
                    {{
                      project.projectName
                        ? project.projectName.trim().substring(0, 1)
                        : "EP"
                    }}
                  </v-avatar>
                  <v-list-item-title class="ms-3"
                    ><span>{{
                      project.projectName ? project.projectName : "N/A"
                    }}</span></v-list-item-title
                  >
                </v-list-item>
              </v-list-item>
            </v-list>
          </v-list-group>
        </div>
        <v-list-item
          @click="
            activeChild = {
              email: false,
              contacts: false,
              opportunities: false,
              projects: false,
            };
            getUsersDetails(user_id);
            setShowContact(false);
          "
          link
          to="/admin/settings"
          active-class="bg-primary primary--text"
        >
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Email Settings</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <div class="main-panel d-flex">
      <top-navbar
        v-if="isMobile()"
        class=""
        @toggle="toggle = !toggle"
      ></top-navbar>
      <div
        class="side-content content-sidebar"
        v-if="
          checkMobileView(false) == true &&
          this.$route.path != '/admin/settings'
        "
      >
        <v-list class="sidecontent_heading_component">
          <v-form class="d-flex" @submit="handleSearch">
            <v-text-field
              hide-details
              dense
              outlined
              rounded
              single-line
              v-model="searchMessage"
              label="Search"
              append-icon=""
            ></v-text-field>
            <v-btn
              class="ms-2"
              type="submit"
              rounded
              :disabled="searchLoader"
              color="primary"
              ><v-icon v-if="!searchLoader">mdi-magnify</v-icon>
              <v-progress-circular v-else indeterminate size="20" />
            </v-btn>
            <v-btn
              v-if="searchMessage"
              @click="handleClear"
              class="ms-2"
              rounded
              color="error"
              :disabled="searchLoader"
              ><v-icon v-if="!searchLoader">mdi-close-thick</v-icon>
            </v-btn>
          </v-form>
          <div class="d-flex mt-3">
            <!-- <div v-if="userDetails && !userDetails.userToken">
							<form
								action="https://email-provider-backend.herokuapp.com/auth/google?userId=1"
								method="GET"
							>
								<v-btn color="primary" type="submit"
									>Connect Gmail</v-btn
								>
							</form>
						</div> -->
            <div class="ms-auto" v-if="checkInbox()">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="white"
                    class="blue--text"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ searchFilter }}
                    <v-icon class="ms-2">mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in items"
                    :key="index"
                    @click="handleFilter(item)"
                  >
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div class="ms-auto" v-if="checkTrash()">
              <v-btn
                class="ms-2"
                @click="clearTrash(user_id)"
                :disabled="filterLoader"
                color="error"
                ><span v-if="!filterLoader">Clear Trash</span>
                <v-progress-circular v-else indeterminate size="20" />
              </v-btn>
            </div>
          </div>
        </v-list>
        <div class="side-content-view" v-if="checkInbox()">
          <div class="text-center my-5">
            <v-progress-circular v-if="inboxLoader" indeterminate size="40" />
            <strong v-else-if="inbox && inbox.length == 0"
              >No Data found</strong
            >
          </div>
          <v-list-item
            v-for="(data, idx) in inbox"
            :key="idx"
            link
            :to="`/admin/inbox/${data.id}`"
            active-class="bg-primary primary--text "
            :class="
              data.firstMessage.labelIds.includes('UNREAD') &&
              'font-weight-bold'
            "
            @click="
              handleCheck(),
                handleRead(data),
                checkLogs({ id: data.id }),
                addToDB({
                  id: user_id,
                  threadId: data.id,
                })
            "
          >
            <v-avatar class="white--text" size="36" color="primary">
              {{
                data.firstMessage
                  ? data.firstMessage.from
                    ? data.firstMessage.from
                        .trim()
                        .substring(0, 1)
                        .toUpperCase()
                    : "EP"
                  : "EP"
              }}
            </v-avatar>
            <v-list-item-title class="ms-3 py-5 pe-5">
              <p class="mb-0">
                {{ data.firstMessage.from }}
              </p>
              <div class="d-flex">
                <small>{{
                  data.firstMessage.subject &&
                  data.firstMessage.subject.length > 18
                    ? data.firstMessage.subject.slice(0, 18) + "..."
                    : data.firstMessage.subject
                }}</small>
                <small class="ms-auto">
                  {{
                    data.firstMessage.internalDate
                      ? momentTime(data.firstMessage.internalDate)
                      : "N/A"
                  }}
                </small>
              </div>
              <small
                v-html="
                  data.firstMessage.messageText &&
                  data.firstMessage.messageText.length > 20
                    ? data.firstMessage.messageText.slice(0, 20)
                    : data.firstMessage.messageText
                "
              ></small>
            </v-list-item-title>
          </v-list-item>

          <div
            class="text-center mt-5"
            v-if="userDetails && !userDetails.userToken"
          >
            <strong>Please connect your account!</strong>
          </div>
        </div>
        <div class="side-content-view" v-if="checkSent()">
          <div class="text-center my-5">
            <v-progress-circular v-if="sentLoader" indeterminate size="40" />
            <strong v-else-if="sent.length == 0">No Data found</strong>
          </div>
          <v-list-item
            v-for="(data, idx) in sent"
            :key="idx"
            link
            :to="`/admin/sent/${data.id}`"
            active-class="bg-primary primary--text "
            @click="
              handleCheck(),
                checkLogs({ id: data.id }),
                getThreadMsgs({
                  id: user_id,
                  threadId: data.id,
                })
            "
          >
            <v-avatar class="white--text" size="36" color="primary">
              {{
                data.firstMessage
                  ? data.firstMessage.to
                    ? data.firstMessage.to.length > 0
                      ? data.firstMessage.to[0]
                          .trim()
                          .substring(0, 1)
                          .toUpperCase()
                      : "EP"
                    : "EP"
                  : "EP"
              }}
            </v-avatar>
            <v-list-item-title class="ms-3 py-5 pe-5">
              <div class="d-flex overflow-hidden">
                <p
                  class="mb-0"
                  :class="idx != 0 ? 'ml-1' : ''"
                  v-for="(rec, idx) in data.firstMessage.to"
                  :key="idx"
                >
                  {{ rec
                  }}<span v-if="data.firstMessage.to.length - 1 != idx">;</span>
                </p>
              </div>
              <div class="d-flex">
                <small>{{
                  data.firstMessage.subject &&
                  data.firstMessage.subject.length > 18
                    ? data.firstMessage.subject.slice(0, 18) + "..."
                    : data.firstMessage.subject
                }}</small>
                <small class="ms-auto">
                  {{
                    data.firstMessage.internalDate
                      ? momentTime(data.firstMessage.internalDate)
                      : "N/A"
                  }}
                </small>
              </div>

              <small
                v-html="
                  data.firstMessage.messageText &&
                  data.firstMessage.messageText.length > 20
                    ? data.firstMessage.messageText.slice(0, 20)
                    : data.firstMessage.messageText
                "
              ></small>
            </v-list-item-title>
          </v-list-item>
          <div
            class="text-center mt-5"
            v-if="userDetails && !userDetails.userToken"
          >
            <strong>Please connect your account!</strong>
          </div>
        </div>
        <div class="side-content-view" v-if="checkTrash()">
          <div class="text-center my-5">
            <v-progress-circular v-if="trashLoader" indeterminate size="40" />
            <strong v-else-if="trash.length == 0">No Data found</strong>
          </div>
          <v-list-item
            v-for="(data, idx) in trash"
            :key="idx"
            link
            :to="`/admin/trash/${data.id}`"
            active-class="bg-primary primary--text "
            @click="
              handleCheck(),
                getTrashThreadMsgs({
                  id: user_id,
                  threadId: data.id,
                })
            "
          >
            <v-avatar class="white--text" size="36" color="primary">
              {{
                data.firstMessage
                  ? data.firstMessage.to
                    ? data.firstMessage.to.length > 0
                      ? data.firstMessage.to[0]
                          .trim()
                          .substring(0, 1)
                          .toUpperCase()
                      : "EP"
                    : "EP"
                  : "EP"
              }}
            </v-avatar>
            <v-list-item-title class="ms-3 py-5 pe-5">
              <div class="d-flex overflow-hidden">
                <p
                  class="mb-0"
                  :class="idx != 0 ? 'ml-1' : ''"
                  v-for="(rec, idx) in data.firstMessage.to"
                  :key="idx"
                >
                  {{ rec
                  }}<span v-if="data.firstMessage.to.length - 1 != idx">;</span>
                </p>
              </div>
              <div class="d-flex">
                <small>{{
                  data.firstMessage.subject &&
                  data.firstMessage.subject.length > 18
                    ? data.firstMessage.subject.slice(0, 18) + "..."
                    : data.firstMessage.subject
                }}</small>
                <small class="ms-auto">
                  {{
                    data.firstMessage.internalDate
                      ? momentTime(data.firstMessage.internalDate)
                      : "N/A"
                  }}
                </small>
              </div>

              <small
                v-html="
                  data.firstMessage.messageText &&
                  data.firstMessage.messageText.length > 20
                    ? data.firstMessage.messageText.slice(0, 20)
                    : data.firstMessage.messageText
                "
              ></small>
            </v-list-item-title>
          </v-list-item>
          <div
            class="text-center mt-5"
            v-if="userDetails && !userDetails.userToken"
          >
            <strong>Please connect your account!</strong>
          </div>
        </div>
        <div class="side-content-view" v-if="checkArchived()">
          <div class="text-center my-5">
            <v-progress-circular
              v-if="archivedLoader"
              indeterminate
              size="40"
            />
            <strong v-else-if="archived.length == 0">No Data found</strong>
          </div>
          <v-list-item
            v-for="(data, idx) in archived"
            :key="idx"
            link
            :to="`/admin/archived/${data.id}`"
            active-class="bg-primary primary--text "
            @click="
              handleCheck(),
                getArchivedThreadMsgs({
                  id: user_id,
                  threadId: data.id,
                })
            "
          >
            <v-avatar class="white--text" size="36" color="primary">
              {{
                data.firstMessage
                  ? data.firstMessage.from
                    ? data.firstMessage.from
                        .trim()
                        .substring(0, 1)
                        .toUpperCase()
                    : "EP"
                  : "EP"
              }}
            </v-avatar>
            <v-list-item-title class="ms-3 py-5 pe-5">
              <p class="mb-0">
                {{ data.firstMessage.from ? data.firstMessage.from : "N/A" }}
              </p>
              <div class="d-flex">
                <small>{{
                  data.firstMessage.subject &&
                  data.firstMessage.subject.length > 18
                    ? data.firstMessage.subject.slice(0, 18) + "..."
                    : data.firstMessage.subject
                }}</small>
                <small class="ms-auto">
                  {{
                    data.firstMessage.internalDate
                      ? momentTime(data.firstMessage.internalDate)
                      : "N/A"
                  }}
                </small>
              </div>

              <small
                v-html="
                  data.firstMessage.messageText &&
                  data.firstMessage.messageText.length > 20
                    ? data.firstMessage.messageText.slice(0, 20)
                    : data.firstMessage.messageText
                "
              ></small>
            </v-list-item-title>
          </v-list-item>

          <div
            class="text-center mt-5"
            v-if="userDetails && !userDetails.userToken"
          >
            <strong>Please connect your account!</strong>
          </div>
        </div>
        <div class="side-content-view" v-if="checkContacts()">
          <div class="text-center my-5">
            <v-progress-circular
              v-if="contactMessageLoader"
              indeterminate
              size="40"
            />
            <strong v-else-if="contactMessages.length == 0"
              >No Data found</strong
            >
          </div>
          <v-list-item
            v-for="(data, idx) in contactMessages"
            :key="idx"
            link
            :to="`/admin/contacts/${data.id}`"
            active-class="bg-primary primary--text "
            @click="
              handleCheck(),
                getContactThreadMsgs({
                  id: user_id,
                  threadId: data.id,
                })
            "
          >
            <v-avatar class="white--text" size="36" color="primary">
              {{
                data.firstMessage
                  ? data.firstMessage.from
                    ? data.firstMessage.from
                        .trim()
                        .substring(0, 1)
                        .toUpperCase()
                    : "EP"
                  : "EP"
              }}
            </v-avatar>
            <v-list-item-title class="ms-3 py-5 pe-5">
              <p class="mb-0">
                {{ data.firstMessage.from ? data.firstMessage.from : "N/A" }}
              </p>
              <div class="d-flex">
                <small>{{
                  data.firstMessage.subject &&
                  data.firstMessage.subject.length > 18
                    ? data.firstMessage.subject.slice(0, 18) + "..."
                    : data.firstMessage.subject
                }}</small>
                <small class="ms-auto">
                  {{
                    data.firstMessage.internalDate
                      ? momentTime(data.firstMessage.internalDate)
                      : "N/A"
                  }}
                </small>
              </div>

              <small
                v-html="
                  data.firstMessage.messageText &&
                  data.firstMessage.messageText.length > 20
                    ? data.firstMessage.messageText.slice(0, 20)
                    : data.firstMessage.messageText
                "
              ></small>
            </v-list-item-title>
          </v-list-item>

          <div
            class="text-center mt-5"
            v-if="userDetails && !userDetails.userToken"
          >
            <strong>Please connect your account!</strong>
          </div>
        </div>
        <div class="side-content-view" v-if="checkOpportunity()">
          <div class="text-center my-5">
            <v-progress-circular
              v-if="labelMessageLoader"
              indeterminate
              size="40"
            />
            <strong v-else-if="labelMessages.length == 0">No Data found</strong>
          </div>
          <v-list-item
            v-for="(data, idx) in labelMessages"
            :key="idx"
            link
            :to="`/admin/opportunities/${data.id}`"
            active-class="bg-primary primary--text "
            @click="
              handleCheck(),
                getOpportunityThreadMsgs({
                  id: user_id,
                  threadId: data.id,
                })
            "
          >
            <v-avatar class="white--text" size="36" color="primary">
              {{
                data.firstMessage
                  ? data.firstMessage.from
                    ? data.firstMessage.from
                        .trim()
                        .substring(0, 1)
                        .toUpperCase()
                    : "EP"
                  : "EP"
              }}
            </v-avatar>
            <v-list-item-title class="ms-3 py-5 pe-5">
              <p class="mb-0">
                {{ data.firstMessage.from ? data.firstMessage.from : "N/A" }}
              </p>
              <div class="d-flex">
                <small>{{
                  data.firstMessage.subject &&
                  data.firstMessage.subject.length > 18
                    ? data.firstMessage.subject.slice(0, 18) + "..."
                    : data.firstMessage.subject
                }}</small>
                <small class="ms-auto">
                  {{
                    data.firstMessage.internalDate
                      ? momentTime(data.firstMessage.internalDate)
                      : "N/A"
                  }}
                </small>
              </div>

              <small
                v-html="
                  data.firstMessage.messageText &&
                  data.firstMessage.messageText.length > 20
                    ? data.firstMessage.messageText.slice(0, 20)
                    : data.firstMessage.messageText
                "
              ></small>
            </v-list-item-title>
          </v-list-item>

          <div
            class="text-center mt-5"
            v-if="userDetails && !userDetails.userToken"
          >
            <strong>Please connect your account!</strong>
          </div>
        </div>
        <div class="side-content-view" v-if="checkProject()">
          <div class="text-center my-5">
            <v-progress-circular
              v-if="labelMessageLoader"
              indeterminate
              size="40"
            />
            <strong v-else-if="labelMessages.length == 0">No Data found</strong>
          </div>
          <v-list-item
            v-for="(data, idx) in labelMessages"
            :key="idx"
            link
            :to="`/admin/projects/${data.id}`"
            active-class="bg-primary primary--text "
            @click="
              handleCheck(),
                getProjectThreadMsgs({
                  id: user_id,
                  threadId: data.id,
                })
            "
          >
            <v-avatar class="white--text" size="36" color="primary">
              {{
                data.firstMessage
                  ? data.firstMessage.from
                    ? data.firstMessage.from
                        .trim()
                        .substring(0, 1)
                        .toUpperCase()
                    : "EP"
                  : "EP"
              }}
            </v-avatar>
            <v-list-item-title class="ms-3 py-5 pe-5">
              <p class="mb-0">
                {{ data.firstMessage.from ? data.firstMessage.from : "N/A" }}
              </p>
              <div class="d-flex">
                <small>{{
                  data.firstMessage.subject &&
                  data.firstMessage.subject.length > 18
                    ? data.firstMessage.subject.slice(0, 18) + "..."
                    : data.firstMessage.subject
                }}</small>
                <small class="ms-auto">
                  {{
                    data.firstMessage.internalDate
                      ? momentTime(data.firstMessage.internalDate)
                      : "N/A"
                  }}
                </small>
              </div>

              <small
                v-html="
                  data.firstMessage.messageText &&
                  data.firstMessage.messageText.length > 20
                    ? data.firstMessage.messageText.slice(0, 20)
                    : data.firstMessage.messageText
                "
              ></small>
            </v-list-item-title>
          </v-list-item>
          <div
            class="text-center mt-5"
            v-if="userDetails && !userDetails.userToken"
          >
            <strong>Please connect your account!</strong>
          </div>
        </div>
      </div>
      <!-- :class="
          activeView != '' && windowWidth < 1224 ? 'd-none' : ''
        "
        :activeView="activeView" -->
      <dashboard-content class="dashboard-content content-view">
      </dashboard-content>
    </div>
    <v-dialog persistent v-model="invalidModal" max-width="290">
      <v-card class="card-shadow pa-10 text-center">
        <strong>User ID is not valid, Kindly Revisit.</strong>
        <br />
        <v-btn class="mt-5" color="error" @click="reloadPage()">Go Back</v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
import DashboardContent from "./Content.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    TopNavbar,
    DashboardContent,
  },
  computed: {
    ...mapGetters(["showContact"]),
  },
  data() {
    return {
      invalidModal: false,
      validLoader: false,
      toggle: true,
      searchMessage: "",
      searchFilter: "Filter",
      clearSearch: false,
      activeChild: {
        email: false,
        contacts: false,
        opportunities: false,
        projects: false,
      },
      activeView: false,
      searchContact: "",
      searchOpportunity: "",
      searchProjects: "",

      items: [
        { title: "All" },
        { title: "Unread" },
        { title: "Oldest" },
        { title: "Newest" },
      ],
    };
  },

  mounted() {
    if (this.$route.query.id) {
      this.checkValidUID({
        id: this.$route.query.id,
        onSuccess: () => {
          if (this.isValid) {
            localStorage.setItem("user_id", this.$route.query.id);
            this.fillUserID(this.$route.query.id);

            this.getUsersDetails(this.$route.query.id);
            this.getSignatures(this.$route.query.id);
            this.getContacts(this.$route.query.id);
            this.getProjects(this.user_id);
            this.getOpportunities(this.user_id);
          } else {
            this.invalidModal = true;
            localStorage.clear();
          }
        },
      });
    } else {
      if (this.user_id) {
        this.getUsersDetails(this.user_id);
        this.getSignatures(this.user_id);
        this.getContacts(this.user_id);
        this.getProjects(this.user_id);
        this.getOpportunities(this.user_id);
      }
    }
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });

    if (this.$route.path.includes("/admin/inbox")) {
      this.pushToInbox();
      return this.getInbox(this.user_id);
    }
    if (this.$route.path.includes("/admin/sent")) {
      this.pushToSent();
      return this.getSent(this.user_id);
    }
    if (this.$route.path.includes("/admin/trash")) {
      this.pushToTrash();
      return this.getTrash(this.user_id);
    }
    if (this.$route.path.includes("/admin/archived")) {
      this.pushToArchived();
      return this.getArchived(this.user_id);
    }
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    ...mapActions(["setShowContact", "setNewMail"]),
    reloadPage() {
      window.location.reload();
    },
    handleClear(e) {
      e.preventDefault();
      if (this.checkInbox()) {
        this.searchMessage = "";
        this.pushToInbox();
        this.getInbox(this.user_id);
      }
      if (this.checkSent()) {
        this.searchMessage = "";
        this.pushToSent();
        this.getSent(this.user_id);
      }
      if (this.checkTrash()) {
        this.searchMessage = "";
        this.pushToTrash();
        this.getTrash(this.user_id);
      }
      if (this.checkArchived()) {
        this.searchMessage = "";
        this.pushToArchived();
        this.getArchived(this.user_id);
      }
    },
    handleSearch(e) {
      e.preventDefault();
      if (this.checkInbox()) {
        this.pushToInbox();
        this.searchInbox({
          id: this.user_id,
          message: this.searchMessage,
        });
      }
      if (this.checkSent()) {
        this.pushToSent();
        this.searchSent({
          id: this.user_id,
          message: this.searchMessage,
        });
      }
      if (this.checkTrash()) {
        this.pushToTrash();
        this.searchTrash({
          id: this.user_id,
          message: this.searchMessage,
        });
      }
      if (this.checkArchived()) {
        this.pushToArchived();
        this.searchArchived({
          id: this.user_id,
          message: this.searchMessage,
        });
      }
    },
    handleFilter(item) {
      this.pushToInbox();
      this.searchFilter = item.title;
      if (this.searchFilter == "All") {
        this.getInbox(this.user_id);
      }
      if (this.searchFilter == "Unread") {
        this.unreadInbox(this.user_id);
      }
      if (this.searchFilter == "Oldest") {
        this.orderInbox({ id: this.user_id, message: "asc" });
      }
      if (this.searchFilter == "Newest") {
        this.orderInbox({ id: this.user_id, message: "desc" });
      }
    },
    filteredContacts() {
      if (this.searchContact == "") {
        return this.contacts;
      } else {
        let res =
          this.contacts &&
          this.contacts.filter((cntct) =>
            cntct.externalContactName
              .toLowerCase()
              .includes(this.searchContact.toLowerCase())
          );
        return res;
      }
    },
    handleCheck() {
      this.setShowContact(false);
    },
    handleRead(data) {
      if (
        data.firstMessage.labelIds &&
        data.firstMessage.labelIds.includes("UNREAD")
      ) {
        this.markAsRead({
          id: this.user_id,
          threadId: data.id,
          onSuccess: () => {
            this.getInbox(this.user_id);
          },
        });
      }
    },
    routeEmail() {
      this.getInbox(this.user_id);
      this.setShowContact(true);
      this.activeChild.email = !this.activeChild.email;
      this.$router.push("/admin/inbox");
      this.searchMessage = "";
    },
    routeInbox() {
      this.getInbox(this.user_id);
      this.searchMessage = "";
      this.$router.push("/admin/inbox");
    },
    routeSent() {
      this.getSent(this.user_id);
      this.searchMessage = "";
      this.$router.push("/admin/sent");
    },
    routeTrash() {
      this.searchMessage = "";
      this.getTrash(this.user_id);
      this.$router.push("/admin/trash");
    },
    routeArchived() {
      this.searchMessage = "";
      this.getArchived(this.user_id);
      this.$router.push("/admin/archived");
    },
    routeContact() {
      this.searchMessage = "";
      this.setShowContact(true);
      this.activeChild.contacts = !this.activeChild.contacts;
      this.clearMessages();
      this.$router.push("/admin/contacts");
    },
    routeOpportunities() {
      this.searchMessage = "";
      this.setShowContact(true);
      this.activeChild.opportunities = !this.activeChild.opportunities;
      this.clearMessages();
      this.$router.push("/admin/opportunities");
    },
    routeProject() {
      this.searchMessage = "";
      this.setShowContact(true);
      this.activeChild.projects = !this.activeChild.projects;
      this.clearMessages();
      this.$router.push("/admin/projects");
    },
  },
};
</script>

<style scoped>
.bordered {
  border: 1px solid #e7e5e4;
}
.sidebar {
  background: #ececec !important;
}
.side-content {
  min-width: 340px;
  height: 100vh;
  background: #fff;
  border-right: 1px solid #e7e5e4;
}
.dashboard-content {
  min-width: calc(100vw - 623px);
}
.side-content-view {
  height: calc(100vh - 120px) !important;
  overflow-y: auto !important;
}
@media (max-width: 1244px) {
  .side-content {
    width: 100vw;
  }
  .dashboard-content {
    width: 100vw;
  }
}
@media (max-width: 640px) {
  .side-content {
    margin-top: 55px;
    height: calc(100vh - 55px) !important;
  }
  .side-content-view {
    height: calc(100vh - 180px) !important;
  }
  .dashboard-content {
    margin-top: 55px;
  }
}
.sidecontent_heading_component {
  padding-top: 16px;
  background: #e7e5e4 !important;
  height: 120px !important;
  padding: 16px 20px !important;
}
.sidebar_heading_component {
  padding-top: 16px;
  background: #ececec !important;
  height: 120px !important;
}

.contact-list {
  max-height: 230px;
  overflow-y: auto;
}
</style>
