<template>
	<div class="sendMail mt-3">
		<v-container>
			<v-row>
				<v-col cols="12" md="6">
					<v-label>Recipients</v-label>
					<v-autocomplete
						v-model="recipients"
						:items="items"
						outlined
						single-line
						chips
						small-chips
						label="Recipients"
						multiple
					></v-autocomplete>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" md="6">
					<v-label>Subject</v-label>
					<v-text-field
						v-model="subject"
						outlined
						single-line
						label="Subject"
					></v-text-field>
				</v-col>
				<v-col cols="6" md="3" class="align-self-center">
					<v-btn
						class="py-6"
						color="primary"
						@click="addOpportunity = !addOpportunity"
						>Attach Opportunity</v-btn
					>
				</v-col>
				<v-col cols="6" md="3" class="align-self-center">
					<strong>Great Opportunity</strong>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<v-label>Email Body</v-label>
					<v-textarea
						v-model="subject"
						outlined
						single-line
						label="Write something..."
					></v-textarea>
				</v-col>
			</v-row>
			<div class="text-right">
				<v-btn color="primary" class="py-6">Submit</v-btn>
			</div>
		</v-container>
		<v-dialog v-model="addOpportunity" max-width="500">
			<v-card class="card-shadow">
				<v-card-text>
					<div class="d-flex justify-space-between pt-5 pb-3">
						<h2 class="primary--text">Add Opportunity</h2>
						<v-icon @click="addOpportunity = !addOpportunity"
							>mdi-close</v-icon
						>
					</div>
					<v-divider></v-divider>
					<div class="mt-5">
						<v-list nav dense>
							<v-list-item
								link
								active-class="bg-primary primary--text"
							>
								<v-list-item-title
									>Big Opportunity</v-list-item-title
								>
							</v-list-item>
							<v-list-item
								link
								active-class="bg-primary primary--text"
							>
								<v-list-item-title
									>Great Opportunity</v-list-item-title
								>
							</v-list-item>
							<v-list-item
								link
								active-class="bg-primary primary--text"
							>
								<v-list-item-title
									>New Opportunity</v-list-item-title
								>
							</v-list-item>
						</v-list>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				addOpportunity: false,
			};
		},
	};
</script>
<style scoped></style>
