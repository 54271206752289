import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
export default {
	data() {
		return {
			API_ENDPOINT: process.env.VUE_APP_API_ENDPOINT,
			windowWidth: window.innerWidth,
			myrules: {
				required: value => !!value || 'Required.',
				email: value => /.+@.+\..+/.test(value) || 'Must be Email',
				alphanumeric: value =>
					/^[a-z0-9]+$/i.test(value) ||
					'Only aphanumeric values allowed',
				spaces: value =>
					(value || '').indexOf(' ') < 0 || 'No spaces are allowed',
			},
		};
	},
	computed: {
		...mapGetters([
			'user_id',
			'isValid',
			'validationLoader',
			'showContact',
			'newMail',
			'userDetails',
			'inbox',
			'inboxLoader',
			'sent',
			'sentLoader',
			'trash',
			'trashThreads',
			'trashLoader',
			'archived',
			'archivedThreads',
			'archivedLoader',
			'deleteLoader',
			'threads',
			'contactThreads',
			'opportunityThreads',
			'projectThreads',
			'threadLoader',
			'projectLoader',
			'projects',
			'opportunities',
			'opportunityLoader',
			'forwardLoader',
			'replyLoader',
			'contacts',
			'contactsLoader',
			'newMailLoader',
			'searchLoader',
			'filterLoader',
			'logsData',
			'logsLoader',
			'disconnectLoader',
			'contactMessages',
			'contactMessageLoader',
			'labelMessages',
			'labelMessageLoader',
			'createSignatureLoader',
			'mySignature',
		]),
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
		});
	},
	methods: {
		...mapActions([
			'fillUserID',
			'checkValidUID',
			'getUsersDetails',
			'getInbox',
			'getSent',
			'getTrash',
			'getTrashThreadMsgs',
			'getArchived',
			'getArchivedThreadMsgs',
			'getOpportunityThreadMsgs',
			'getProjectThreadMsgs',
			'getContactThreadMsgs',
			'deleteMail',
			'archiveMail',
			'getProjects',
			'getOpportunities',
			'forwardMail',
			'replyMail',
			'replyToAll',
			'getContacts',
			'sendNewMail',
			'searchInbox',
			'searchSent',
			'searchTrash',
			'searchArchived',
			'orderInbox',
			'unreadInbox',
			'clearTrash',
			'moveToInbox',
			'markAsRead',
			'checkLogs',
			'addOpportunityLogs',
			'addProjectLogs',
			'disconnectMail',
			'getContactMessages',
			'getLabelMessages',
			'clearMessages',
			'createSignature',
			'updateSignature',
			'getSignatures',
			'deleteSignature',
			'getThreadMsgs',
			'getInboxMessages',
			'addToDB',
		]),
		checkMobileView(arg) {
			if (this.windowWidth < 1224) {
				return this.showContact;
			} else {
				return !arg;
			}
		},
		isMobile() {
			if (this.windowWidth > 640) {
				return false;
			} else {
				return true;
			}
		},
		onResize() {
			this.windowWidth = window.innerWidth;
		},
		filteredOpportunity(opporSearch) {
			if (opporSearch != '') {
				let obj =
					this.opportunities &&
					this.opportunities.filter(oppor =>
						oppor.opportunityName
							.toLowerCase()
							.includes(opporSearch.toLowerCase())
					);
				return obj;
			} else {
				return this.opportunities;
			}
		},

		filteredProjects(projectSearch) {
			if (projectSearch == '') {
				return this.projects;
			} else {
				let res =
					this.projects &&
					this.projects.filter(project =>
						project.projectName
							.toLowerCase()
							.includes(projectSearch.toLowerCase())
					);
				return res;
			}
		},

		checkInbox() {
			return this.$route.path.includes('/admin/inbox');
		},
		pushToInbox() {
			return this.$router.push('/admin/inbox');
		},
		checkSent() {
			return this.$route.path.includes('/admin/sent');
		},
		pushToSent() {
			return this.$router.push('/admin/sent');
		},
		checkTrash() {
			return this.$route.path.includes('/admin/trash');
		},
		pushToTrash() {
			return this.$router.push('/admin/trash');
		},
		checkArchived() {
			return this.$route.path.includes('/admin/archived');
		},
		checkContacts() {
			return this.$route.path.includes('/admin/contacts');
		},
		checkOpportunity() {
			return this.$route.path.includes('/admin/opportunities');
		},
		checkProject() {
			return this.$route.path.includes('/admin/projects');
		},
		pushToArchived() {
			return this.$router.push('/admin/archived');
		},
		momentUnix: function (date) {
			return moment.unix(date).format('DD-MMM-YYYY hh:mm A');
		},
		momentPicker: function (date) {
			if (date) {
				return moment(date).format('DD MMM YYYY');
			}
		},
		simpleMomentUnix: function (date) {
			return moment.unix(date).format('DD-MMM-YYYY');
		},
		momentTime: function (date) {
			return moment.unix(date / 1000).format('hh:mm A');
		},
		momentTimeDate: function (date) {
			return moment.unix(date / 1000).format('DD/MM/YYYY hh:mm A');
		},
		moment: function (date) {
			if (date) {
				return moment(date).format('DD-MMM-YYYY');
			}
		},
	},
};
