import Repository from './Repository';
const GET_PROJECTS = '/api/getProjects';
const GET_OPPORTUNITY = '/api/getOppurtunities';
const GET_CONTACTS = '/api/getUserContacts';
const GET_CONTACT_MESSAGES = '/gmail/contactMessages';
const GET_LABEL_MESSAGES = '/gmail/getLabelMessage';
const GMAIL_SIGNATURE = '/gmail/signature';
const CHECK_USER = '/api/checkUser';
export default {
	getProjects(payload) {
		return Repository.get(`${GET_PROJECTS}?userId=${payload.id}`);
	},
	getLabelMessages({ id, payload }) {
		return Repository.get(
			`${GET_LABEL_MESSAGES}/${id}?${payload.label}=${payload.id}`
		);
	},
	getOpportunities(payload) {
		return Repository.get(`${GET_OPPORTUNITY}?userId=${payload.id}`);
	},
	getOppotunityMessages({ id, payload }) {
		return Repository.post(`${GET_CONTACT_MESSAGES}/${id}`, payload);
	},
	getContacts(payload) {
		return Repository.get(`${GET_CONTACTS}/${payload.id}`);
	},
	getContactMessages({ id, contactEmail }) {
		return Repository.post(`${GET_CONTACT_MESSAGES}/${id}`, {
			contactEmail,
		});
	},

	createSignature({ id, text }) {
		return Repository.post(`${GMAIL_SIGNATURE}/${id}`, { text: text });
	},
	updateSignature({ id, text }) {
		return Repository.put(`${GMAIL_SIGNATURE}/${id}`, { text: text });
	},
	getSignatures({ id }) {
		return Repository.get(`${GMAIL_SIGNATURE}/${id}`);
	},
	deleteSignature({ id }) {
		return Repository.delete(`${GMAIL_SIGNATURE}/${id}`);
	},
	checkValidUID({ id }) {
		return Repository.get(`${CHECK_USER}/${id}`);
	},
};
