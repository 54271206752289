import { RepositoryFactory } from '../../repository/RepositoryFactory';
var Gmail = RepositoryFactory.get('gmail');
let state = {
	userDetails: {},
	inbox: [],
	inboxLoader: false,
	sent: [],
	threads: [],
	threadLoader: false,
	sentLoader: false,
	trash: [],
	trashThreads: [],
	trashLoader: false,
	archived: [],
	archivedThreads: [],
	logsData: {},
	contactThreads: [],
	projectThreads: [],
	opportunityThreads: [],
	archivedLoader: false,
	deleteLoader: false,
	forwardLoader: false,
	replyLoader: false,
	newMailLoader: false,
	searchLoader: false,
	filterLoader: false,
	logsLoader: false,
	disconnectLoader: false,
};
let getters = {
	userDetails: state => state.userDetails,
	inbox: state => state.inbox,
	sent: state => state.sent,
	trash: state => state.trash,
	trashThreads: state => state.trashThreads,
	archived: state => state.archived,
	archivedThreads: state => state.archivedThreads,
	threads: state => state.threads,
	threadLoader: state => state.threadLoader,
	contactThreads: state => state.contactThreads,
	projectThreads: state => state.projectThreads,
	opportunityThreads: state => state.opportunityThreads,
	logsData: state => state.logsData,
	inboxLoader: state => state.inboxLoader,
	sentLoader: state => state.sentLoader,
	trashLoader: state => state.trashLoader,
	archivedLoader: state => state.archivedLoader,
	deleteLoader: state => state.deleteLoader,
	forwardLoader: state => state.forwardLoader,
	replyLoader: state => state.replyLoader,
	newMailLoader: state => state.newMailLoader,
	searchLoader: state => state.searchLoader,
	filterLoader: state => state.filterLoader,
	logsLoader: state => state.logsLoader,
	disconnectLoader: state => state.disconnectLoader,
};
let actions = {
	async getUsersDetails({ commit }, payload) {
		try {
			let { data } = await Gmail.getUserDetails({ id: payload });
			// console.log({ data });
			if (data.success) {
				commit('setUserDetails', data.message);
			} else {
				// alert(data.message);
			}
		} catch (e) {
			// alert(e.response.data.message);
		}
	},
	async disconnectMail({ commit }, payload) {
		commit('setDisconnectLoader', true);
		let onSuccess = payload['onSuccess'] ? payload['onSuccess'] : () => {};
		try {
			let { data } = await Gmail.disconnectMail({
				id: payload.id,
			});
			// console.log({ data });
			if (data.success) {
				onSuccess();
				commit('setDisconnectLoader', false);
				commit('setLogsData', {});
				commit('setArchived', []);
				commit('setTrash', []);
				commit('setSent', []);
				commit('setInbox', []);
			} else {
				// alert(data.message);
				commit('setDisconnectLoader', false);
			}
		} catch (e) {
			// alert(e.response.data.message);
			commit('setDisconnectLoader', false);
		}
	},
	async addToDB({ commit }, payload) {
		try {
			let { data } = await Gmail.addToDB(payload);
		} catch (e) {}
	},
	async getInboxMessages({ commit, dispatch }, payload) {
		try {
			let { data } = await Gmail.getInbox({ id: payload });
			// console.log({ data });
			if (data.success) {
				commit('setInbox', data.data);
				commit('setInboxLoader', false);
			} else {
				commit('setInboxLoader', false);
			}
		} catch (e) {
			if (e?.response?.data?.message?.includes('429')) {
				dispatch('getInbox', payload);
			} else {
				commit('setInboxLoader', false);
			}
		}
	},
	async getInbox({ commit, dispatch }, payload) {
		commit('setInboxLoader', true);
		try {
			dispatch('getInboxMessages', payload);
		} catch (e) {
			commit('setInboxLoader', false);
		}
	},
	async getTrashThreadMsgs({ commit }, payload) {
		commit('setThreadLoader', true);
		try {
			let { data } = await Gmail.getThreadMsgs(payload);
			// console.log({ data });
			if (data.success) {
				commit('setTrashThreads', data.data);
				commit('setThreadLoader', false);
			} else {
				// alert(data.message);
				commit('setThreadLoader', false);
			}
		} catch (e) {
			// alert(e.response.data.message);
			commit('setThreadLoader', false);
		}
	},
	async getArchivedThreadMsgs({ commit }, payload) {
		commit('setThreadLoader', true);
		try {
			let { data } = await Gmail.getThreadMsgs(payload);
			// console.log({ data });
			if (data.success) {
				commit('setArchivedThreads', data.data);
				commit('setThreadLoader', false);
			} else {
				// alert(data.message);
				commit('setThreadLoader', false);
			}
		} catch (e) {
			// alert(e.response.data.message);
			commit('setThreadLoader', false);
		}
	},
	async getThreadMsgs({ commit }, payload) {
		commit('setThreadLoader', true);
		try {
			let { data } = await Gmail.getThreadMsgs(payload);
			// console.log({ data });
			if (data.success) {
				commit('setThreads', data.data);
				commit('setThreadLoader', false);
			} else {
				// alert(data.message);
				commit('setThreadLoader', false);
			}
		} catch (e) {
			// alert(e.response.data.message);
			commit('setThreadLoader', false);
		}
	},
	async getContactThreadMsgs({ commit }, payload) {
		commit('setThreadLoader', true);
		try {
			let { data } = await Gmail.getThreadMsgs(payload);
			// console.log({ data });
			if (data.success) {
				commit('setContactThreads', data.data);
				commit('setThreadLoader', false);
			} else {
				// alert(data.message);
				commit('setThreadLoader', false);
			}
		} catch (e) {
			// alert(e.response.data.message);
			commit('setThreadLoader', false);
		}
	},
	async getProjectThreadMsgs({ commit }, payload) {
		commit('setThreadLoader', true);
		try {
			let { data } = await Gmail.getThreadMsgs(payload);
			// console.log({ data });
			if (data.success) {
				commit('setProjectThreads', data.data);
				commit('setThreadLoader', false);
			} else {
				// alert(data.message);
				commit('setThreadLoader', false);
			}
		} catch (e) {
			// alert(e.response.data.message);
			commit('setThreadLoader', false);
		}
	},
	async getOpportunityThreadMsgs({ commit }, payload) {
		commit('setThreadLoader', true);
		try {
			let { data } = await Gmail.getThreadMsgs(payload);
			// console.log({ data });
			if (data.success) {
				commit('setOpportunityThreads', data.data);
				commit('setThreadLoader', false);
			} else {
				// alert(data.message);
				commit('setThreadLoader', false);
			}
		} catch (e) {
			// alert(e.response.data.message);
			commit('setThreadLoader', false);
		}
	},
	async searchInbox({ commit }, payload) {
		commit('setInboxLoader', true);
		commit('setSearchLoader', true);
		try {
			let { data } = await Gmail.searchInbox({
				id: payload.id,
				message: payload.message,
			});
			// console.log({ data });
			if (data.success) {
				commit('setInbox', data.data);
				commit('setInboxLoader', false);
				commit('setSearchLoader', false);
			} else {
				// alert(data.message);
				commit('setInboxLoader', false);
				commit('setSearchLoader', false);
			}
		} catch (e) {
			// alert(e.response.data.message);
			commit('setInboxLoader', false);
			commit('setSearchLoader', false);
		}
	},
	async unreadInbox({ commit }, payload) {
		commit('setInboxLoader', true);
		commit('setFilterLoader', true);
		try {
			let { data } = await Gmail.unreadInbox({
				id: payload,
				message: 'unread',
			});
			// console.log({ data });
			if (data.success) {
				commit('setInbox', data.data);
				commit('setInboxLoader', false);
				commit('setFilterLoader', false);
			} else {
				// alert(data.message);
				commit('setInboxLoader', false);
				commit('setFilterLoader', false);
			}
		} catch (e) {
			// alert(e.response.data.message);
			commit('setInboxLoader', false);
			commit('setFilterLoader', false);
		}
	},
	async orderInbox({ commit }, payload) {
		commit('setInboxLoader', true);
		commit('setFilterLoader', true);
		try {
			let { data } = await Gmail.orderInbox({
				id: payload.id,
				message: payload.message,
			});
			// console.log({ data });
			if (data.success) {
				commit('setInbox', data.data);
				commit('setInboxLoader', false);
				commit('setFilterLoader', false);
			} else {
				// alert(data.message);
				commit('setInboxLoader', false);
				commit('setFilterLoader', false);
			}
		} catch (e) {
			// alert(e.response.data.message);
			commit('setInboxLoader', false);
			commit('setFilterLoader', false);
		}
	},
	async getSent({ commit }, payload) {
		commit('setSentLoader', true);
		try {
			let { data } = await Gmail.getAll({ id: payload, label: 'SENT' });
			// console.log({ data });
			if (data.success) {
				commit('setSent', data.data);
				commit('setSentLoader', false);
			} else {
				// alert(data.message);
				commit('setSentLoader', false);
			}
		} catch (e) {
			// alert(e.response.data.message);
			commit('setSentLoader', false);
		}
	},
	async searchSent({ commit }, payload) {
		commit('setSentLoader', true);
		commit('setSearchLoader', true);
		try {
			let { data } = await Gmail.searchAll({
				id: payload.id,
				label: 'SENT',
				message: payload.message,
			});
			// console.log({ data });
			if (data.success) {
				commit('setSent', data.data);
				commit('setSentLoader', false);
				commit('setSearchLoader', false);
			} else {
				// alert(data.message);
				commit('setSentLoader', false);
				commit('setSearchLoader', false);
			}
		} catch (e) {
			// alert(e.response.data.message);
			commit('setSentLoader', false);
			commit('setSearchLoader', false);
		}
	},
	async markAsRead({ commit }, payload) {
		let onSuccess = payload['onSuccess'] ? payload['onSuccess'] : () => {};
		try {
			let { data } = await Gmail.markAsRead({
				id: payload.id,
				threadId: payload.threadId,
			});
			// console.log({ data });
			if (data.success) {
				onSuccess();
			} else {
				// alert(data.message);
			}
		} catch (e) {
			// alert(e.response.data.message);
		}
	},
	async checkLogs({ commit }, payload) {
		commit('setAddLogs', true);
		try {
			let { data } = await Gmail.checkLogs({
				id: payload.id,
			});
			// console.log({ data });
			if (data.success) {
				commit('setLogsData', data.data);
				commit('setAddLogs', false);
			} else {
				commit('setAddLogs', false);
				// alert(data.message);
			}
		} catch (e) {
			commit('setAddLogs', false);
			// alert(e.response.data.message);
		}
	},
	async getTrash({ commit }, payload) {
		commit('setTrashLoader', true);
		try {
			let { data } = await Gmail.getAll({ id: payload, label: 'TRASH' });
			// console.log({ data });
			if (data.sucess) {
				commit('setTrash', data.data);
				commit('setTrashLoader', false);
			} else {
				// alert(data.message);
				commit('setTrashLoader', false);
			}
		} catch (e) {
			// alert(e.response.data.message);
			commit('setTrashLoader', false);
		}
	},
	async clearTrash({ commit }, payload) {
		commit('setFilterLoader', true);
		try {
			let { data } = await Gmail.clearTrash({ id: payload });
			// console.log({ data });
			if (data.success) {
				commit('setTrash', []);
				commit('setTrashThreads', []);
				commit('setFilterLoader', false);
			} else {
				// alert(data.message);
				commit('setFilterLoader', false);
			}
		} catch (e) {
			// alert(e.response.data.message);
			commit('setFilterLoader', false);
		}
	},
	async searchTrash({ commit }, payload) {
		commit('setTrashLoader', true);
		commit('setSearchLoader', true);
		try {
			let { data } = await Gmail.searchAll({
				id: payload.id,
				label: 'TRASH',
				message: payload.message,
			});
			// console.log({ data });
			if (data.success) {
				commit('setTrash', data.data);
				commit('setTrashLoader', false);
				commit('setSearchLoader', false);
			} else {
				// alert(data.message);
				commit('setTrashLoader', false);
				commit('setSearchLoader', false);
			}
		} catch (e) {
			// alert(e.response.data.message);
			commit('setTrashLoader', false);
			commit('setSearchLoader', false);
		}
	},
	async getArchived({ commit }, payload) {
		commit('setArchivedLoader', true);
		try {
			let { data } = await Gmail.getAll({
				id: payload,
				label: 'ARCHIVED',
			});
			// console.log({ data });
			if (data.success) {
				commit('setArchived', data.data);
				commit('setArchivedLoader', false);
			} else {
				// alert(data.message);
				commit('setArchivedLoader', false);
			}
		} catch (e) {
			// alert(e.response.data.message);
			commit('setArchivedLoader', false);
		}
	},
	async searchArchived({ commit }, payload) {
		commit('setArchivedLoader', true);
		commit('setSearchLoader', true);
		try {
			let { data } = await Gmail.searchAll({
				id: payload.id,
				label: 'ARCHIVED',
				message: payload.message,
			});
			// console.log({ data });
			if (data.success) {
				commit('setArchived', data.data);
				commit('setArchivedLoader', false);
				commit('setSearchLoader', false);
			} else {
				// alert(data.message);
				commit('setArchivedLoader', false);
				commit('setSearchLoader', false);
			}
		} catch (e) {
			// alert(e.response.data.message);
			commit('setArchivedLoader', false);
			commit('setSearchLoader', false);
		}
	},
	async deleteMail({ commit }, payload) {
		commit('setDeleteLoader', true);
		let onSuccess = payload['onSuccess'] ? payload['onSuccess'] : () => {};
		try {
			let { data } = await Gmail.deleteMail({
				id: payload.id,
				threadId: payload.threadId,
			});
			// console.log({ data });
			if (data.success) {
				onSuccess();
				commit('setDeleteLoader', false);
			} else {
				// alert(data.message);
				commit('setDeleteLoader', false);
			}
		} catch (e) {
			// alert(e.response.data.message);
			commit('setDeleteLoader', false);
		}
	},
	async moveToInbox({ commit }, payload) {
		commit('setFilterLoader', true);
		let onSuccess = payload['onSuccess'] ? payload['onSuccess'] : () => {};
		try {
			let { data } = await Gmail.moveToInbox({
				id: payload.id,
				threadId: payload.threadId,
			});
			// console.log({ data });
			if (data.success) {
				onSuccess();
				commit('setFilterLoader', false);
			} else {
				// alert(data.message);
				commit('setFilterLoader', false);
			}
		} catch (e) {
			// alert(e.response.data.message);
			commit('setFilterLoader', false);
		}
	},
	async archiveMail({ commit }, payload) {
		commit('setArchivedLoader', true);
		let onSuccess = payload['onSuccess'] ? payload['onSuccess'] : () => {};
		try {
			let { data } = await Gmail.archiveMail({
				id: payload.id,
				threadId: payload.threadId,
			});
			// console.log({ data });
			if (data.success) {
				onSuccess();
				commit('setArchivedLoader', false);
			} else {
				// alert(data.message);
				commit('setArchivedLoader', false);
			}
		} catch (e) {
			// alert(e.response.data.message);
			commit('setArchivedLoader', false);
		}
	},
	async forwardMail({ commit }, payload) {
		commit('setForwardLoader', true);
		let onSuccess = payload['onSuccess'] ? payload['onSuccess'] : () => {};
		try {
			let { data } = await Gmail.forwardMail({
				userId: payload.uid,
				payload,
			});
			// console.log({ data });
			if (data.success) {
				onSuccess();
				commit('setForwardLoader', false);
			} else {
				// alert(data.message);
				commit('setForwardLoader', false);
			}
		} catch (e) {
			// alert(e.response.data.message);
			commit('setForwardLoader', false);
		}
	},
	async replyMail({ commit }, payload) {
		commit('setReplyLoader', true);
		let onSuccess = payload['onSuccess'] ? payload['onSuccess'] : () => {};
		try {
			let { data } = await Gmail.replyMail({
				userId: payload.uid,
				payload,
			});
			// console.log({ data });
			if (data.success) {
				onSuccess();
				commit('setReplyLoader', false);
			} else {
				// alert(data.message);
				commit('setReplyLoader', false);
			}
		} catch (e) {
			// alert(e.response.data.message);
			commit('setReplyLoader', false);
		}
	},
	async replyToAll({ commit }, payload) {
		commit('setReplyLoader', true);
		let onSuccess = payload['onSuccess'] ? payload['onSuccess'] : () => {};
		try {
			let { data } = await Gmail.replyToAll({
				userId: payload.uid,
				payload,
			});
			// console.log({ data });
			if (data.success) {
				onSuccess();
				commit('setReplyLoader', false);
			} else {
				// alert(data.message);
				commit('setReplyLoader', false);
			}
		} catch (e) {
			// alert(e.response.data.message);
			commit('setReplyLoader', false);
		}
	},
	async sendNewMail({ commit }, payload) {
		commit('setNewMailLoader', true);
		let onSuccess = payload['onSuccess'] ? payload['onSuccess'] : () => {};
		try {
			let { data } = await Gmail.sendNewMail({
				userId: payload.uid,
				payload: payload.payload,
			});
			// console.log({ data });
			if (data.success) {
				onSuccess();
				commit('setNewMailLoader', false);
			} else {
				// alert(data.message);
				commit('setNewMailLoader', false);
			}
		} catch (e) {
			// alert(e.response.data.message);
			commit('setNewMailLoader', false);
		}
	},
	async addOpportunityLogs({ commit }, payload) {
		commit('setAddLogs', true);
		let onSuccess = payload['onSuccess'] ? payload['onSuccess'] : () => {};
		try {
			let { data } = await Gmail.addOpportunityLogs({
				userId: payload.uid,
				payload: payload.payload,
			});
			// console.log({ data });
			if (data.success) {
				onSuccess();
				commit('setAddLogs', false);
			} else {
				// alert(data.message);
				commit('setAddLogs', false);
			}
		} catch (e) {
			// alert(e.response.data.message);
			commit('setAddLogs', false);
		}
	},
	async addProjectLogs({ commit }, payload) {
		commit('setAddLogs', true);
		let onSuccess = payload['onSuccess'] ? payload['onSuccess'] : () => {};
		try {
			let { data } = await Gmail.addProjectLogs({
				userId: payload.uid,
				payload: payload.payload,
			});
			// console.log({ data });
			if (data.success) {
				onSuccess();
				commit('setAddLogs', false);
			} else {
				// alert(data.message);
				commit('setAddLogs', false);
			}
		} catch (e) {
			// alert(e.response.data.message);
			commit('setAddLogs', false);
		}
	},
};
let mutations = {
	setUserDetails(state, payload) {
		state.userDetails = payload;
	},
	setInbox(state, payload) {
		state.inbox = payload;
	},
	setSent(state, payload) {
		state.sent = payload;
	},
	setTrash(state, payload) {
		state.trash = payload;
	},
	setTrashThreads(state, payload) {
		state.trashThreads = payload;
	},
	setArchived(state, payload) {
		state.archived = payload;
	},
	setArchivedThreads(state, payload) {
		state.archivedThreads = payload;
	},
	setThreads(state, payload) {
		state.threads = payload;
	},
	setContactThreads(state, payload) {
		state.contactThreads = payload;
	},
	setProjectThreads(state, payload) {
		state.projectThreads = payload;
	},
	setOpportunityThreads(state, payload) {
		state.opportunityThreads = payload;
	},
	setThreadLoader(state, payload) {
		state.threadLoader = payload;
	},
	setInboxLoader(state, payload) {
		state.inboxLoader = payload;
	},
	setSentLoader(state, payload) {
		state.sentLoader = payload;
	},
	setTrashLoader(state, payload) {
		state.trashLoader = payload;
	},
	setArchivedLoader(state, payload) {
		state.archivedLoader = payload;
	},
	setDeleteLoader(state, payload) {
		state.deleteLoader = payload;
	},
	setForwardLoader(state, payload) {
		state.forwardLoader = payload;
	},
	setNewMailLoader(state, payload) {
		state.newMailLoader = payload;
	},
	setReplyLoader(state, payload) {
		state.replyLoader = payload;
	},
	setSearchLoader(state, payload) {
		state.searchLoader = payload;
	},
	setFilterLoader(state, payload) {
		state.filterLoader = payload;
	},
	setLogsData(state, payload) {
		state.logsData = payload;
	},
	setAddLogs(state, payload) {
		state.logsLoader = payload;
	},
	setDisconnectLoader(state, payload) {
		state.disconnectLoader = payload;
	},
};

export default {
	state,
	getters,
	actions,
	mutations,
};
